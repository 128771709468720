import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import GrayButton from "../../components/GrayButton"
import OtherSettings from "./OperatingTime"
import OperatingTime from "./OperatingTime"
import Faciliites from "./Facilities"
import PageTitle from "../../components/PageTitle"

export const BusinessSettingsContent = ({ businessDataJson, user, facilitiesDataJson, operatingTimeJson }) => {

    const [businessData, setBusinessData] = useState()
    const facilitiesData = JSON.parse(facilitiesDataJson)
    const operatingTimeData = (JSON.parse(operatingTimeJson))
    const [activateText, setActivateText] = useState('Activate')
    const [deactivateText, setDeactivateText] = useState('Deactivate')
    const [deactivateState, setDeactivateState] = useState(false)
    const [activateState, setActivateState] = useState(false)

    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [msg, setMsg] = useState()

    useEffect(() => {
        setBusinessData(JSON.parse(businessDataJson))

    }, [])

    const activateBusiness = async (businessId, userId, obj) => {


        setActivateText('Working...')

        const endpoint = REACT_APP_API_URL + "?operation=ACTIVATE_BUSINESS"

        const data = new FormData()
        data.append('business_id', businessId)
        data.append('user_id', userId)

        let rsp = await axios.post(endpoint, data)
            .then(response => {
                //setMsg(response.data)
                navigate(0)
            })
            .catch(error => setMsg(error))



    }

    const deactivateBusiness = async (businessId, userId, obj) => {

        setDeactivateText('Working...')

        let endpoint = REACT_APP_API_URL + "?operation=DEACTIVATE_BUSINESS"
        endpoint += "&business_id=" + businessId
        endpoint += "&user_id=" + userId



        const data = new FormData()
        data.append('business_id', businessId)
        data.append('user_id', userId)

        let rsp = await axios.post(endpoint, data)
            .then(response => {
                //setMsg(response.data)
                navigate(0)
            })
            .catch(error => setMsg(error))

    }






    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Business Settings"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        url={"/account/business-profile/" + businessData?.business_id}
                        title={"Back"}
                    />


                </div>
            </div>

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl'>
                    <div className=''>
                        {businessData?.title}
                    </div>
                    <div>-</div>
                    <div className=' text-green-700 text-[14px]'>
                        Primary Business
                    </div>
                </div>
                <div className=' text-sm mt-2'>

                    Other information or settings for this business can be found and/or configured here. The settings can be updated at any time.
                </div>


            </div>
            <div className=' h-[32px]'></div>

            <div>
                {businessData && operatingTimeData &&
                    <OperatingTime businessData={businessData} operatingTimeData={operatingTimeData} />}
            </div>
            <div className=' h-[32px]'></div>

            <div>
                {businessData && facilitiesData &&
                    <Faciliites businessData={businessData} facilitiesData={facilitiesData} />}
            </div>
            <div className=' h-[32px]'></div>

            <div className=' p-[15px] pb-[32px] rounded-[8px] border-[1px] border-b-gray-500/30'>
                <div className='font-bold mb-[12px] text-xl'>{businessData?.active == 'active' ? deactivateText : activateText} {businessData?.title}</div>
                <div className=' mb-2'>You are about to deactivate this business. To continue click "Deactivate" button below.</div>

                <div className=' mt-5 mb-5'>
                    <ul className=' list-outside list-disc ml-5 space-y-2'>
                        <li>
                            <div className=' font-semibold'>
                                Visible in emails
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    When deactivated, this business will cease to appear in any of Skyepages account in future.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div className=' font-semibold'>
                                Receives notifications
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This business will cease to receive notifications via emails in future. Those with this business's email can still send emails to this business.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className=' border-b-[1px] border-gray-500/30 mb-[32px] '></div>
                <div className=''>
                    {
                        businessData?.active == 'active' && !deactivateState && <button

                            onClick={
                                () => setDeactivateState(true)
                            }
                            className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]
}">
                            Deactivate
                        </button>

                    }
                    {
                        businessData?.active == 'active' && deactivateState &&
                        <button

                            onClick={
                                () => setDeactivateState(false)
                            }
                            className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                            Cancel
                        </button>
                    }

                    {
                        businessData?.active != 'active' && !activateState && <button

                            onClick={
                                () => setActivateState(true)
                            }
                            className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                            Activate
                        </button>
                    }

                    {
                        businessData?.active != 'active' && activateState &&
                        <button

                            onClick={
                                () => setActivateState(false)
                            }
                            className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                            Cancel
                        </button>
                    }
                </div>
                {
                    deactivateState && <div className=' transition duration-1000 mt-[32px] h-auto flex flex-col space-y-8 place-content-center place-items-center text-center border-[1px] border-gray-500/20 rounded-[8px] px-5 py-[32px]'>
                        <div className=' font-bold text-xl border-[1px]  w-full p-2 bg-gray-200 rounded-[8px]'>
                            Deactivating...
                        </div>
                        <div>
                            You are about to deactivate this business. You can activate it later in future. However, when you deactivate this business, it will no longer be visible to public viewers. Those that have email address for this business can still send email to this business. To continue, click the "Deactivate" button below.
                        </div>
                        <div className=' border-b-[1px] border-gray-500/50 w-full'></div>

                        <div>
                            <button

                                onClick={
                                    () => {
                                        const choice = window.confirm("You are about to deactivate this business. Click yes to proceed or Cancel to abort.")

                                        if (choice == true) {
                                            deactivateBusiness(businessData?.business_id, user.user_id)
                                        } else {
                                            setDeactivateState(false)
                                        }
                                    }
                                }
                                className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                                {deactivateText}
                            </button>
                        </div>
                    </div>
                }


                {
                    activateState && <div className=' mt-[32px] h-auto flex flex-col space-y-8 place-content-center place-items-center text-center border-[1px] border-gray-500/20 rounded-[8px] px-4 pb-[32px] pt-5'>
                        <div className=' font-bold text-xl border-[1px]  w-full p-2 bg-gray-200 rounded-[8px]'>
                            Activating...
                        </div>
                        <div>
                            You are about to activate this business. Once activated, this business, will be visible to public viewers. Those that have email address for this business can send email to this business. To continue, click the "Activate" button below.
                        </div>
                        <div className=' border-b-[1px] border-gray-500/50 w-full'></div>
                        <div>
                            <button

                                onClick={
                                    () => {
                                        const choice = window.confirm("You are about to deactivate this business. Click yes to proceed or Cancel to abort.")

                                        if (choice == true) {
                                            activateBusiness(businessData?.business_id, user.user_id)
                                        } else {
                                            setActivateState(false)
                                        }
                                    }
                                }
                                className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                                {activateText}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}