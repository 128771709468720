import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'

const workHrs = [
    {
        day: "Mon",
        time: "9:30 am - 10:00 pm"
    },
    {
        day: "Tue",
        time: "9:30 am - 10:00 pm"
    },
    {
        day: "Wed",
        time: "9:30 am - 10:00 pm"
    },
    {
        day: "Thu",
        time: "9:30 am - 10:00 pm"
    },
    {
        day: "Fri",
        time: "9:30 am - 10:00 pm"
    },
    {
        day: "Sat",
        time: "12:30 pm - 7:00 pm"
    },
    {
        day: "Sun",
        time: "Closed"
    },
]
const WorkDays = ({ businessId }) => {
    const [workDays, setWorkDays] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const getWorkDays = async (data) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_OPERATING_TIME_FOR_DISPLAY&id=" + businessId
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data)
                const dataObject = JSON.parse(data)

                console.log(dataObject)
                setWorkDays(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getWorkDays(businessId)
    }, [])


    return (
        <div>
            <div className=' border-[1px] rounded-sm border-gray-500/30 py-[15px] px-4'>
                <div className='font-bold text-[19px]'>Hours</div>
                <div className='font-bold text-[15px] mb-2'>Working Hours</div>
                <div className=' divide-y-[1px] divide-blue-400/20 border-[1px] border-blue-400/50 rounded-[5px]'>

                    {
                        workDays !== null ? workDays.map((item) => {
                            return (
                                <div className=' flex place-content-between text-[14px] bg-blue-300/15 px-3 py-[4px] rounded-[3px]'>
                                    <div>
                                        {item.day}
                                    </div>
                                    <div>
                                        {item.time}
                                    </div>
                                </div>
                            )
                        }) : 'Loading...'
                    }
                </div>
            </div>
        </div>
    )
}

export default WorkDays
