import { useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../../context/GlobalContext"
import AccountGridTable from "./HomeGridTable"
import SearchResultGrid from "../../../search/SearchResultGrid"
import PageTitle from "../../components/PageTitle"

export const HomeContent = ({ preloadedValues, user }) => {

    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [userData, setUserData] = useState(null)

    const [srcData, setSrcData] = useState([])

    const getBusinesses = async () => {
        let endpoint = REACT_APP_API_URL + '?operation=READ_BUSINESSES_BY_USERID&user_id=' + user.user_id
        //window.location.href = endpoint
        let response = await fetch(
            endpoint,
            {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }

            }
        );

        const result = await response.json();
        setSrcData(result.data)
        //console.log(result.data)
    }

    useEffect(() => {
        getBusinesses()
    }, [])












    return (
        <div className=' mt-6'>

            <PageTitle title={"Home"} />

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl'>
                    <div>
                        {parsedPreloadedValue.email}
                    </div>
                    <div>-</div>
                    <div className=' text-orange-300'>
                        Primary
                    </div>
                </div>
                <div className=' text-sm mt-2'>

                    Below are businesses owned by this account. You can add more businesses, edit them and manage them under this account.
                </div>

                <div className=' mt-5'>
                    <ul className=' list-outside list-disc ml-5 space-y-2'>
                        <li>
                            <div className=' font-semibold'>
                                Visible in emails
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This email may be used as the 'author' or 'committer' of changes to this account and businesses and assets owned by this account.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div className=' font-semibold'>
                                Receives notifications
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This email address is the default used for Skyepages notifications, i.e., replies to issues, etc.                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className=' h-[32px]'></div>
            <div
                className=' text-2xl font-bold rounded-[8px] border-[1px] border-b-gray-500/30 p-3'
            >
                My Businesses
            </div>

            <div className=' mt-8'>

                <SearchResultGrid data={srcData} />
            </div>
        </div>
    )
}