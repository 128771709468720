import React, { useContext, useEffect, useState } from 'react'
import { UtilContext } from '../../context/UtilContext'
import { BiCycling, BiLibrary, BiWine } from 'react-icons/bi'
import { BsBank2, BsClock, BsClockFill, BsMusicNote, BsMusicPlayer } from 'react-icons/bs'
import { ClockIcon, LibraryIcon } from '@heroicons/react/outline'
import { CiClock1, CiLock } from 'react-icons/ci'
import H32 from '../../components/body/H32'
import ContactDetails from './ContactDetails'
import BusinessDetails from './BusinessDetails'
import H24 from '../../components/body/H24'
import H16 from '../../components/body/H16'
import { TiSocialTumbler } from 'react-icons/ti'
import { FaFacebook, FaWineGlass } from 'react-icons/fa'
import { GiWineGlass } from 'react-icons/gi'
import { LuFacebook, LuLinkedin, LuTwitch, LuTwitter, LuWine, LuX } from 'react-icons/lu'
import { GrCafeteria, GrLounge } from 'react-icons/gr'
import { PiFacebookLogoLight, PiImagesSquareLight } from 'react-icons/pi'
import { BusinessContext } from './BusinessContext'
import { GlobalContext } from '../../context/GlobalContext'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import axios from 'axios'
import DisplayFacilities from './DisplayFacilities'
import SocialMediaIcons from '../account/components/SocialMediaIcons'
import { CgFacebook } from 'react-icons/cg'
import { MdFacebook, MdOutlineFacebook } from 'react-icons/md'
import { AiOutlineFacebook } from 'react-icons/ai'
import GetDates from './GetDates'
import { Circle, CircleOutlined } from '@mui/icons-material'
import VisitorViewCount from './VisitorViewCount'
import ReviewCount from './ReviewCount'
import ContactIcons from './ContactIcons'
import Founded from './Founded'
import CountryStateCity from '../account/components/CountryStateCity'

const facility = [
    {
        title: "bar",
        icon: <LuWine />
    },
    {
        title: "cafeteria",
        icon: <GrCafeteria />
    },
    {
        title: "library",
        icon: <BiLibrary />
    },
    {
        title: "lounge",
        icon: <GrLounge />
    },
    {
        title: "music",
        icon: <BsMusicNote />
    }
]
const RightColumn = ({ id }) => {


    return (
        <div id='right-col'>
            <RightColumnHeader id={id} />
            {/* <H32 />
            <H32 /> */}
            {/* <ContactDetails />
            <H32 />
            <H32 />
            <BusinessDetails /> */}
        </div>
    )
}

export const RightColumnHeader = ({ id }) => {
    const { getStars } = useContext(UtilContext)
    const { businessData } = useContext(BusinessContext)
    const { REACT_APP_API_UPLOADS_URI,
        REACT_APP_IMG_PATH,
        REACT_APP_API_URL
    } = useContext(GlobalContext)
    const [rating, setRating] = useState(0)
    const [loading, setLoading] = useState(true)
    const [facilities, setFacilities] = useState(null)
    const [workDays, setWorkDays] = useState(null)

    function getImg(item) {
        return item?.img ? REACT_APP_API_UPLOADS_URI + item?.img : REACT_APP_IMG_PATH + "/img/dummy.jpeg"
    }

    function getRating(id) {
        const endpoint = REACT_APP_API_URL + "?operation=GET_RATING&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = (res.data.data)
                console.log(data)
                setRating(data[0].rating)

                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getRating(id)
    }, [])

    useEffect(() => {
        if (rating) {
            console.log(rating)
        }
    }, [rating])

    const getFacilities = async (data) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_FACILITIES&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])
                const dataObject = JSON.parse(data)

                console.log(dataObject)
                setFacilities(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getFacilities(id)
    }, [])

    const getWorkDays = async (data) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_OPERATING_TIME_FOR_DISPLAY&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data)
                const dataObject = JSON.parse(data)

                console.log(dataObject)
                setWorkDays(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getWorkDays(id)
    }, [])


    return (
        <div>
            <div className='flex  '>
                <div className=' max-w-[100px] max-h-[100px] md:max-w-[150px] md:max-h-[150px] relative flex place-content-center place-items-center border-[1px] border-black p-[2px]'>
                    <img
                        className=' object-cover w-full h-full'
                        src={getImg(businessData)} alt="" />
                </div>
                <div className=' w-full'>
                    <div className=' ml-[15px] mt-[-5px]'>
                        <div className='text-[21px] md:text-[30px] font-semibold tracking-tighter leading-[1em]'>
                            {businessData?.title}
                        </div>

                        <div className=' text-[13px] font-semibold md:text-[14px] mt-[5px] tracking-tighter leading-[1em]'>
                            {businessData?.business_phrases}
                        </div>

                        <div className=' flex mt-[3px]  place-items-center space-x-2'>

                            {
                                rating > 0 &&
                                <div className=' flex space-x-1 w-fit border-r-[1px] border-gray-500/80 pr-[5px]  md:text-[30px] place-items-center'>
                                    {/* {getStars(2.6)} */}
                                    <div>
                                        <Stack spacing={1}>
                                            <Rating name="half-rating"
                                                defaultValue={rating.toFixed(1)}
                                                precision={0.05}
                                                size='large'
                                                readOnly
                                                icon={<Circle style={{ color: "green", fontSize: 23 }} />}
                                                emptyIcon={<CircleOutlined style={{ color: "green", fontSize: 23 }} />}
                                            />

                                        </Stack>
                                    </div>
                                    <div className=' text-[19px] font-bold flex place-content-center place-items-center'>
                                        {rating.toFixed(1)}
                                    </div>
                                </div>
                            }

                            {
                                rating == 0 &&
                                <div className=' flex space-x-1 w-fit border-r-[1px] border-gray-500/80 pr-[5px] text-[22px] md:text-[30px] place-items-center'>
                                    {/* {getStars(2.6)} */}
                                    <div>
                                        <Stack spacing={1}>
                                            <Rating name="half-rating"
                                                defaultValue={rating * 0}
                                                precision={0.05}
                                                size='large'
                                                readOnly
                                                icon={<Circle style={{ color: "green", fontSize: 25 }} />}
                                                emptyIcon={<CircleOutlined style={{ color: "green", fontSize: 25 }} />}
                                            />
                                        </Stack>
                                    </div>
                                    <div className=' text-[19px] font-bold flex place-content-center place-items-center'>
                                        {rating * 0}
                                    </div>
                                </div>
                            }



                        </div>

                        <div>
                            {
                                businessData?.business_id &&
                                <ReviewCount businessId={businessData?.business_id} />
                            }

                        </div>

                        <div className=' flex gap-2 place-content-start place-items-center mt-[0px]'>


                            {
                                businessData?.fbsoc &&
                                <div>
                                    <SocialMediaIcons
                                        icon={<LuFacebook />}
                                        handleUrl={"http://facebook.com/" + businessData?.fbsoc}
                                    />
                                </div>
                            }
                            {
                                businessData?.xsoc &&
                                <div>
                                    <SocialMediaIcons
                                        icon={<LuX />}
                                        handleUrl={"http://X.com/" + businessData?.xsoc}
                                    />
                                </div>
                            }
                            {
                                businessData?.linksoc &&
                                <div>
                                    <SocialMediaIcons
                                        icon={<LuLinkedin />}
                                        handleUrl={"http://linkedin.com/company/" + businessData?.linksoc}
                                    />
                                </div>
                            }
                            <div className=' ml-2'>
                                {
                                    businessData?.business_id &&
                                    <VisitorViewCount size={28} businessId={businessData?.business_id} />
                                }
                            </div>
                        </div>

                        <div className=' mt-[2px]'>
                            {
                                businessData?.established &&
                                <Founded businessData={businessData} />
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className=' mt-[4px] flex place-items-center place-content-start space-x-2'>
                <div className=' w-fit  flex flex-wrap place-content-start place-items-center space-x-[3px]'>

                    <div className=''>
                        {workDays && businessData &&
                            <GetDates
                                workDays={workDays}
                                businessData={businessData}
                            />}
                    </div>
                </div>

            </div>



            {/* Facilities */}

            <H16 />
            <div className=' border-t-[1px]  border-b-[1px] border-gray-400/60 py-2 text-[14px] flex space-x-4'>
                <span>Facilities:</span>
                {facilities &&
                    <DisplayFacilities facilities={facilities} />
                }

            </div>


            <H16 />



        </div>
    )
}



export default RightColumn
