import React, { createContext, useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import axios from 'axios'
import { GiCancel } from 'react-icons/gi'
import { GrStatusGood } from 'react-icons/gr'

const CountryContext = createContext()

const CountryProvider = ({ children }) => {
    const countryName = "country_code"
    const countryId = "country_code"
    const stateName = "state_code"
    const stateId = "state_code"
    const cityName = "city"
    const cityId = "city"
    const callMobile = "call_mobile"
    const callMobileId = "call_mobile"
    const callCode = "call_code"
    const callCodeId = "call_code"
    const countryText = "country_text"
    const stateText = "state_text"
    const cityText = "city"
    const [countryList, setCountryList] = useState(null)
    const [stateList, setStateList] = useState(null)
    const [cityList, setCityList] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [selectedState, setSelectedState] = React.useState();
    const [filteredStates, setFilteredStates] = React.useState()
    const [selectedCity, setSelectedCity] = React.useState();
    const [filteredCities, setFilteredCities] = React.useState();
    const [filteredCountry, setFilteredCountry] = React.useState();

    const getCountriesList = () => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_COUNTRIES"
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {

            const data = (result.data.data)
            setCountryList(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    const getStateList = () => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_STATES"
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {

            const data = (result.data.data)
            setStateList(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    const getCityList = () => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_CITIES"
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {

            const data = (result.data.data)
            setCityList(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    const filterCountry = (selectedCountry) => {
        //alert(selectedCountry)
        let result = null
        if (selectedCountry !== "" && selectedCountry !== null) {
            result = countryList?.filter((country) => {
                return (
                    country?.iso2 === selectedCountry
                )
            })
        }
        //alert(JSON.stringify(result))
        setFilteredCountry(result)
        return result
    }

    const filterStates = (selectedCountry) => {
        //alert(selectedCountry)
        let results = null
        if (selectedCountry !== "" && selectedCountry !== null) {
            results = stateList?.filter((state) => {
                return (
                    state?.country_code?.toLowerCase().includes(selectedCountry?.toLowerCase())
                )
            })
        }
        setFilteredStates(results)
        return results
    }

    const filterCities = (selectedState) => {
        //alert(selectedState)
        let results = null
        if (selectedState !== "" && selectedState !== null) {
            //alert('s')
            results = cityList?.filter((city) => {
                return (
                    city?.state_code?.toLowerCase().includes(selectedState?.toLowerCase()) &&
                    city?.country_code?.toLowerCase().includes(selectedCountry?.toLowerCase())
                )
            })
        }
        //alert((results))
        setFilteredCities(results)
        return results
    }

    useEffect(() => {

        getCountriesList()
        getStateList()
        getCityList()
    }, [])

    const value = {
        countryName,
        countryId,
        stateName,
        stateId,
        cityName,
        cityId,
        countryList,
        stateList,
        cityList,
        selectedCountry,
        setSelectedCountry,
        selectedState,
        setSelectedState,
        filteredStates,
        setFilteredStates,
        filterStates,
        selectedCity,
        setSelectedCity,
        filteredCities,
        setFilteredCities,
        filterCities,
        filteredCountry,
        setFilteredCountry,
        filterCountry,
        callMobile,
        callMobileId,
        callCode,
        callCodeId,
        countryText,
        stateText
    }
    return (
        <CountryContext.Provider value={value}>
            {children}
        </CountryContext.Provider>
    )
}

export { CountryContext, CountryProvider }


const CityStateCountryTwo = ({ register, changeHandler, errors, setValue, setValueHandler }) => {

    const {
        countryList,
        stateList,
        cityList
    } = useContext(CountryContext)

    return (
        <div>

            {
                countryList && stateList && cityList &&
                <CountryContent
                    register={register}
                    changeHandler={changeHandler}
                    errors={errors}
                    setValue={setValue}
                    setValueHandler={setValueHandler}
                />
            }
        </div>
    )
}

export const CountryContent = ({ register, changeHandler, errors, setValue, setValueHandler }) => {
    new Promise((resolve) => setTimeout(resolve, 500));
    const {
        countryName,
        countryId,
        stateName,
        cityName,
        selectedCountry,
        setSelectedCountry,
        selectedState,
        setSelectedState,
        countryList,
        stateList,
        cityList,
        filteredStates,
        setFilteredStates,
        filterStates,
        filterCities,
        filteredCountry,
        filterCountry,
        countryText,
        stateText
    } = useContext(CountryContext)


    useEffect(() => {
        var countryObj = document.getElementById(countryId)
        try {
            var countryVal = countryObj[countryObj.selectedIndex].value
            if (countryVal !== null && countryVal.length == 2) {

                setSelectedCountry(countryVal)
                filterStates(countryVal)
            }
        } catch (e) {

            console.log(e)
        }
    }, [])



    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='font-bold mb-[5px]' htmlFor="title">Country</label>
                <input
                    {...register(countryText, { onchange: changeHandler })}
                    type="hidden"
                />
                <select
                    {...register(countryName, {
                        onChange: async (e) => {
                            changeHandler(e)
                            if (e.target.value === "" || e.target.value === null) {
                                setValueHandler(countryText, "")
                                setValue(countryText, "")
                            } else {
                                filterCountry(e.target.value)
                                setValueHandler(countryText, e.target[e.target.selectedIndex].text)
                                setValue(countryText, e.target[e.target.selectedIndex].text)
                            }

                            setValueHandler(stateName, "")
                            setValueHandler(stateText, "")
                            setValueHandler(cityName, "")

                            setSelectedCountry(e.target.value)
                            filterStates(e.target.value)
                            filterCities(null)

                        }
                    })}
                    className=' h-[40px] px-2 bg-gray-300/15 rounded-[5px] border-[1px] border-gray-300 shadow-md'
                    placeholder="Country"
                    id={countryId}

                >
                    <option value={""}>--Choose Country--</option>
                    {countryList?.map((value, key) => {
                        return (
                            <option
                                className=''
                                value={value.iso2} key={key}

                            >
                                {value.name}
                            </option>
                        );
                    })}
                </select>
                {errors.country && (
                    <div className=' text-red-400 mt-[5px] text-[15px]'>
                        <span className=' text-red-500 font-bold'>{errors.country.message}
                        </span>
                    </div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    Select the country your business is registered in. Where a business is located in more than one country, please select the country that you intend to use.
                </label>
            </div>

            {
                filteredStates !== null && filteredStates !== undefined &&
                <StateContent
                    register={register}
                    changeHandler={changeHandler}
                    errors={errors}
                    setValue={setValue}
                    setValueHandler={setValueHandler}
                />
            }

            {
                <div>
                    <PhoneCodeContent
                        register={register}
                        changeHandler={changeHandler}
                        errors={errors}
                        setValue={setValue}
                        setValueHandler={setValueHandler}
                    />

                </div>
            }

        </div>
    )
}

export const PhoneCodeContent = ({ register, changeHandler, errors, setValue, setValueHandler }) => {
    new Promise((resolve) => setTimeout(resolve, 500));
    //const [callCode, setCallCode] = useState()

    const normalizeInput = (value) => {
        if (!value) return value;
        const currentValue = value.replace(/[^\d]/g, '');
        const cvLength = currentValue.length;

        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    };

    const {
        filteredCountry,
        setFilteredCountry,
        filterCountry,
        callCode,
        callCodeId,
        callMobile,
        callMobileId
    } = useContext(CountryContext)

    useEffect(() => {
        if (filteredCountry) {
            var callCodeObj = document.getElementById(callCodeId)
            callCodeObj.value = filteredCountry[0]?.phone_code
            setValueHandler(callCode, filteredCountry[0]?.phone_code)
            setValue(callCode, filteredCountry[0]?.phone_code)
        }
    }, [filteredCountry])

    return (
        <div>
            <div className=' flex flex-col'>
                <div className='font-bold  mb-3' htmlFor="title">Mobile</div>
                <div className='  w-[100%] mb-[-8px] pb-4 text-[14px] '>
                    <div>
                        <div className=' font-normal mb-0'>Guideline: Follow the guide below when entering mobile. Leave the first zero in your mobile.</div>
                        <ul className=' text-[14px] space-y-[0px]'>
                            <li className=' flex place-items-center space-x-2'>
                                <div>(070) 4804-8394 <b>: <span className='text-red-500'>Wrong  </span></b></div>
                                <GiCancel size={20} />
                            </li>
                            <li className=' flex place-items-center space-x-2'>
                                <div>(704) 804-8394 <b>: <span className='text-green-600'>Right</span></b></div>
                                <GrStatusGood size={20} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className=' grid grid-cols-12 gap-2'>
                <div className=' col-span-3 flex flex-col'>
                    <label className='font-bold mb-[5px]' htmlFor="">Call Code</label>
                    <input
                        id={callCodeId}
                        {...register(callCode, {
                            onChange: (e) => {
                                changeHandler(e)
                            },

                        })}

                        type="text"
                        disabled='disabled'
                        className=' text-center text-[19px] shadow-md bg-green-300/30 px-2 h-[40px] w-full border-[3px] border-green-300 rounded-[5px]'
                    />
                </div>
                <div className=' col-span-9 flex flex-col'>
                    <label className='font-bold mb-[5px]' htmlFor="">Mobile</label>
                    <input
                        id={callMobileId}
                        {...register(callMobile, {
                            onChange: (e) => {
                                let val = normalizeInput(e.target.value)
                                e.target.value = val
                                changeHandler(e)
                            },

                        })}


                        type="text"
                        className='px-3 text-[19px] h-[40px] w-full border-[3px] bg-blue-500/20 border-blue-300 rounded-[5px]'
                    />
                </div>
            </div>
        </div>
    )
}


export const StateContent = ({ register, changeHandler, errors, setValue, setValueHandler }) => {

    new Promise((resolve) => setTimeout(resolve, 500));

    const {
        stateName,
        stateId,
        cityName,
        cityList,
        filteredStates,
        setSelectedState,
        selectedState,
        selectedCountry,
        selectedCity,
        setSelectedCity,
        filteredCities,
        setFilteredCities,
        filterCities,
        stateText
    } = useContext(CountryContext)



    useEffect(() => {

        var stateObj = document.getElementById(stateId)
        try {
            if (stateObj) {

                var stateVal = stateObj[stateObj.selectedIndex].value

                if (stateVal !== null && stateVal.length >= 2) {
                    setSelectedState(stateVal)
                    //alert(stateVal)
                    filterCities(stateVal)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [])



    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='font-bold mb-[5px]' htmlFor="title">State</label>
                <input
                    {...register(stateText, { onchange: changeHandler })}
                    type="hidden"
                />
                <select
                    id={stateId}
                    {...register(stateName, {
                        onChange: async (e) => {
                            changeHandler(e)

                            if (e.target.value === "" || e.target.value === null) {
                                setValueHandler(stateText, "")
                                setValue(stateText, "")
                            } else {
                                setValueHandler(stateText, e.target[e.target.selectedIndex].text)
                                setValue(stateText, e.target[e.target.selectedIndex].text)
                            }

                            setValueHandler(cityName, "")
                            setSelectedState(e.target.value)
                            filterCities(e.target.value)

                        }
                    })}
                    placeholder="State"
                    value={selectedState}
                    className='h-[40px] px-2 bg-gray-300/15 rounded-[5px] border-[1px] border-gray-300 shadow-md'
                >
                    <option value={""}>--Choose State--</option>
                    {filteredStates?.map((e, key) => {
                        return (
                            <option value={e.iso2} key={key}>
                                {e.name}
                            </option>
                        );
                    })}
                </select>
                {errors && (
                    <div className=' text-red-400 mt-[5px] text-[15px]'>
                        <span className=' text-red-500 font-bold'>{errors.message}
                        </span>
                    </div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    Select the state where your business is situated in. Where the business has more than one location, please select the state that you intend to use.
                </label>
            </div>

            {
                filteredCities !== null && filteredCities !== undefined &&
                <CityContent
                    register={register}
                    changeHandler={changeHandler}
                    errors={errors}
                    setValue={setValue}
                    setValueHandler={setValueHandler}
                />
            }
        </div>
    )
}


export const CityContent = ({ register, changeHandler, errors, setValue, setValueHandler }) => {


    new Promise((resolve) => setTimeout(resolve, 500));
    const {
        cityName,
        cityId,
        filteredCities,
        filterCities,
        setSelectedCity,
        selectedCity,
        cityText
    } = useContext(CountryContext)



    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='font-bold mb-[5px]' htmlFor="title">City</label>
                <select
                    id={cityId}
                    {...register(cityName, {
                        onChange: (e) => {
                            changeHandler(e)
                            setSelectedCity(e.target.value)
                            if (e.target.value === "" || e.target.value === null) {
                                setValueHandler(cityText, "")
                                setValue(cityText, "")
                            } else {
                                setValueHandler(cityText, e.target[e.target.selectedIndex].text)
                                setValue(cityText, e.target[e.target.selectedIndex].text)
                            }
                        }
                    })}
                    placeholder="City"
                    value={selectedCity}
                    className='h-[40px] px-2 bg-gray-300/15 rounded-[5px] border-[1px] border-gray-300 shadow-md'

                >
                    <option value={""}>--Choose City--</option>
                    {filteredCities?.map((city, key) => {
                        return (
                            <option value={city.name} key={key}>
                                {city.name}
                            </option>
                        );
                    })}
                </select>
                {errors && (
                    <div className=' text-red-400 mt-[5px] text-[15px]'>
                        <span className=' text-red-500 font-bold'>{errors.message}
                        </span>
                    </div>)}

                <label className='font-normal text-[14px] mt-[2px] leading-[18px]'>
                    Select the city where your business is situated. Where the business has more than one location, please select the city that you intend to use.
                </label>
            </div>


        </div>
    )
}

export default CityStateCountryTwo
