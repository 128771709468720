import React, { useState, useMemo } from 'react'


const CountryDropDown = ({ register, changeHandler, jsondata, controlName, labelTop, labelBottom, placeholder, error }) => {
    const [value, setValue] = useState('')




    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6'>
                <label className='font-bold mb-[8px]' htmlFor="title">{labelTop}</label>

                <select id=""
                    {...register(controlName, {
                        onChange: changeHandler
                    })}
                    className='border-[1px] outline-none 
                    border-gray-500/40 bg-gray-500/5 
                    shadow-md rounded-md px-[10px] 
                    py-[10px] text-[15px]
                     w-[100%]'
                    placeholder='Select Country Code'

                >
                    <option value="" data-countrycode="Select" >Select Country Code</option>
                    {jsondata.map((item) => {
                        return (
                            <option key={item?.id} value={item.countryName}>{item.countryName}</option>
                        )
                    })}

                </select>



                {error && (
                    <div className=' text-red-400 mt-[5px] text-[15px]'>
                        <span className=' text-red-500 font-bold'>{error.message}
                        </span>
                    </div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    {labelBottom}
                </label>
            </div>
        </div>

    )
}

export default CountryDropDown
