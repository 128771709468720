import React from 'react'
import { PiListBulletsBold } from 'react-icons/pi'

const PrivacyContent = () => {
    return (
        <div>
            <div className=' mx-5'>
                <div className=' max-w-[1200px] mx-auto w-full'>
                    <div className=' grid grid-cols-12'>
                        <div className=' col-span-12 sm:col-span-12 md:col-span-10 lg:col-span-7  space-y-8 text-[17px] tracking-normal'>

                            <p>

                                <span className=' text-2xl leading-tight font-bold'>Privacy Statement</span>
                            </p>
                            <p>
                                <b className=' text-blue-900'>Storcs</b>, a business directory agency, provides this privacy statement also known as the “Privacy Statement” and is strongly committed to the protection of our users' privacy. Storcs is an online business directory agency dedicated to providing our clients with the best business directory service.
                            </p>

                            <p>
                                As a reputable agency, we are committed to the security of customers' data, and our users privacy. This Privacy Statement describes what we do, how we collect, store, use, share, and protect the personal information of users of our website(s), <b><u><a href="/">storcs.com</a></u></b>. This statement proves our commitment in ensuring that our website meets the GDPR requirements.
                            </p>

                            <p>
                                By using Skyepage website, you agree to our privacy statement. We welcome our users to question our statement and ask for more clarity where possible.
                            </p>

                            <p>
                                <b>1.  What is “Personal Information”? </b>
                            </p>

                            <p>
                                Personal information refers to any piece of user information or data that can be effective in identifying an individual directly (such as first name, last name, address, e-mail address, or telephone number) or indirectly (such as place, time or preference of access to our systems, products or services).
                            </p>

                            <p>
                                Business or Contact names, addresses and telephone numbers of a business person which are used in publishing telephone or business directory (with user's consent) that is available publicly do not qualify under the scope of personal information. Sometimes, some of this prior mentioned pieces of information may qualify as business information which is not covered by this Statement.
                            </p>

                            <p>
                                <b>2.  Collection of Personal Information </b>
                            </p>

                            <p>
                                We gather information from the users of our website(s) through online forms on our website(s) which are supplied voluntarily by our website users. We also gather information when our users contact us directly via email communitation or request more information from us about our products or services, online surveys, blogs, forums and website cookies. Depending on the channel of communication with us, the information we gather may include:
                            </p>

                            <ul className=' space-y-3 list-disc ml-4'>
                                <li>
                                    <b>Identification:</b> This pieces of information refers to users basic contact details such as usernames, password, date of birth, gender, language preferences, email address, and/or phone or mobile information, as well as a user photo (avatar).
                                </li>
                                <li>
                                    <b>Comments, SMS or Messages:</b> When users write, send, or receive messages through our website, such information may be collected. Additionally, we collect information when users provide comments or reviews on Storcs Website(s).
                                </li>
                                <li>
                                    <b>Marketing, Communications:</b> Users who indicate their preferences in receiving marketing and communication information from us consent to our collection of such information. Where users have consented, we utilize users personal data to deliver marketing and advertising for Storcs products and services to meet users preference and interests.
                                </li>
                                <li>
                                    <b>Technical Data:</b> To keep our site secure, we collect technical data which refers to users internet protocol (IP) addresses and device information used to access Storcs Website(s) or App.
                                </li>
                            </ul>

                            <p>
                                <b className=' flex place-content-start space-x-1 place-items-center'>
                                    <span><PiListBulletsBold /></span>
                                    <span>Cookies</span>
                                </b>
                            </p>
                            <p>
                                We may also collect information about user preferences through cookies, as well as from Social Media, for use cases where our users might consent to sign up an account with us via third party apps or websites like Google or Facebook Accounts.
                            </p>

                            <p>

                                For cases where we obtain information from third party services such as Google or Facebook during account creation or login, users have consented and granted these third parties acknowledgement to share their information or data with us. Information collected from such third parties may include, users email address and/or users public profile. These information are needed to authenticate users who choose and conset to signup or login via social media route.
                            </p>

                            <p>
                                Users of Storcs website(s) are not obliged to supply any personal infromation prior to viewing our website content. However, for users who have signed up to our website(s) care should be excercised in sharing information. Such information shared may be visible publicly and can be viewed by others.
                            </p>
                            <p>
                                If a user declines to provide us with personal information, this will lead to no harm, although promotional events, product information and other activities may not be available to the user.
                                through portions of our sites which require such personalized information or applications. Users can at any time opt-out of receiving communications from us.
                            </p>
                            <p>
                                Personal data of users will not be retained longer than is reasonably required for the purpose(s) for which it was provided. This will vary for different categories of personal information. We retain users personal information for varying periods of time based on the following factors:
                            </p>

                            <ul className='  list-disc list-inside'>
                                <li>
                                    National and Regional legal requirements in various jurisdictions;

                                </li>
                                <li>
                                    Limits imposed by National and Provincial laws;

                                </li>
                                <li>
                                    Necessities arising from potential or actual legal disputes; and

                                </li>
                                <li>
                                    Global best practices provided by data protection guidelines.

                                </li>
                            </ul>

                            <p>
                                We erase any users personal information that is no longer needed for the purpose for which it was collected. Users of our website(s) can request us to delete at their own consent, any personal information collected about them. Please contact <b><a href="mailto:gdpr@storcs.com"><u>gdpr@storcs.com</u></a></b>.                            </p>

                            <p>
                                <b>3.  Use, Personal Information Sharing </b>
                            </p>

                            <p>
                                We collect and use the above detailed personal information to run promotions, surveys, reward programs and other activities, to understand our users' prefernces, and to generally manage users expectations.
                            </p>

                            <p>
                                <b className=' flex place-content-start space-x-1 place-items-center'>
                                    <span><PiListBulletsBold /></span>
                                    <span>Cookies</span>
                                </b>
                            </p>
                            <p>
                                Cookies are tiny pieces of software programs that run on users web browsers. These programs enable us to track users preferences as they use our website(s) and functions embedded in them.
                            </p>
                            <p>
                                With users consent, we use cookies and similar technologies to understand users usage of our website in order to provide them with relevant functions, and to offer them better usage experience. Cookies enable us to create better targetted functionality, analytics, marketing, advertising, and a personalized experience for users.

                            </p>
                            <p>
                                <b>4. Consent </b>
                            </p>

                            <p>
                                When you provide us with your personal information, you consent to our collection, use and disclosure of that information as itemized in this Statement. This statement can also be amended periodically, before, as at collection, or after collection, use or disclosure, as the case may be. To opt out of having their personal information collected, used or disclosed in this manner, users can do the following:

                            </p>

                            <ul className=' list-inside list-disc'>
                                <li>
                                    Do not submit your personal information to us;

                                </li>
                                <li>
                                    Opt-out of providing personal information,

                                </li>
                                <li>
                                    Or do not opt-in on services offered at the time of collection

                                </li>
                            </ul>

                            <p>
                                Users can withdraw consent after submission of personal infromation. Please send an e-mail to <span><b><u><a href="mailto:gdpr@storcs.com">gdpr@storcs.com</a></u></b></span>. If consent is withdrawn, we may not be able to provide some products or services to the user.

                            </p>
                            <p>
                                Where required by law, we may collect, use or disclose users personal information without their consent. Such cases may include debt collection, legal inquiry by a government agency, or adherence to court order.

                            </p>

                            <p>
                                <b>5.  Sharing  </b>
                            </p>

                            <p>
                                To continue providing our users with with top tier products and services, we sometimes share your personal information with third party service providers, including other companies we work with. These third parties carry out certain services we require to serve you better, and may use your personal information for the intended purpose while serving you.
                            </p>
                            <p>
                                Further more, we may disclose users personal information if we are subject to share users information in compliance with any legal requirement, or when there is need to protect the rights, property or safety of our business, our users, or others. This may include sharing data with agencies such as local police, for the purposes of fraud protection, or to assist with an investigation, or adherence to court order.

                            </p>
                            <p>
                                Personal data may be exchanged with groups acting as our agents or contractors when performing services that are intended to serve our users or customers. Our agents may be located in different parts of the world and are bound by foreign such as requirements for disclosure of personal data to government agencies in those countries. Our contractual agreements with such agents or contractors ensure that personal information shared with them must be used for the intended purposes, and that personal information shall be treated as prescribed in this statement.

                            </p>
                            <p>
                                To know more about our procedures to protect personal information you can write to us at <a href="mailto:gdpr@storcs.com"><b><u>gdpr@storcs.com</u></b></a>
                            </p>

                            <p>
                                <b>6.  Terms of Use, Statement Update</b>
                            </p>

                            <p>
                                Using <b><u>Storcs</u></b> Website(s), and any disputes that may come as a result, is subject to this Statement and governed by our Terms of Use, including its choice of law provisions. We reserve the right to amend and update this statement and these updates shall be effected when and if appropriate, unconditionally. Users are encouraged to check our privacy statement periodically to be abreast with any changes that may have been made.
                            </p>

                            <p>
                                <b>7.  Access, Contact</b>
                            </p>

                            <p>
                                When we receive a written request from users, Storcs will provide the requester, access to their personal information, subject to any presciptions of the applicable legislation. If users notice any errors or omissions in their personal information, users can advise Storcs in writing and we will promptly amend them where appropriate. For any concerns about our privacy statement, the security of your personal information or our compliance with the applicable legislation on the protection of personal information, please contact our privacy unit at:
                            </p>

                            <ul>
                                <li>
                                    <b>Privacy & Protection Unit (PPU)</b>
                                </li>
                                <li>
                                    Skyepage Directory Services

                                </li>
                                <li>
                                    gdpr@storcs.com
                                </li>
                            </ul>
                        </div>
                        <div className=' col-span-0 md:col-span-2 lg:col-span-5'></div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PrivacyContent
