import { useForm } from "react-hook-form"
import ResetPwSchema from "../../../resetpw/ResetPwSchema"
import { zodResolver } from "@hookform/resolvers/zod"
import { useContext, useState } from "react"
import AlertContext from "../../../../context/AlertContext"
import { GlobalContext } from "../../../../context/GlobalContext"
import axios from "axios"
import { CgSpinner } from "react-icons/cg"
import GreenButton from "../../components/GreenButton"
import { UtilContext } from "../../../../context/UtilContext"
import GrayButton from "../../components/GrayButton"
import PageTitle from "../../components/PageTitle"

export const ResetPwdForm = ({ user, preloadedValues }) => {
    const parsedPreloadedValue = (JSON.parse(preloadedValues))
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const [loading, setLoading] = useState(false)
    const { REACT_APP_API_URL, REACT_APP_HOST } = useContext(GlobalContext)
    const [userData, setUserData] = useState(null)
    const [successMsg, setSuccessMsg] = useState(null)
    const [working, setWorking] = useState(false)
    const { getHostPortProtocol } = useContext(UtilContext)

    const { showAlert, timerRef, closeAlert, fadeOutAlert,
        fadeOutAlertWithParam
    } = useContext(AlertContext)

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const sendEmail = async (data) => {
        //alert(JSON.stringify(data))
        //return false
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let endpoint = REACT_APP_API_URL + "?operation=FORGOT_PASSWORD"
        data["app_host"] = REACT_APP_HOST //getHostPortProtocol(window)
        data["name"] = user?.first_name
        //endpoint += "&app_host=" + data["app_host"]
        //endpoint += "&email=" + data["email"]
        //window.location.href = endpoint
        //return false

        let rst = axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (response) {
            console.log("here")
            console.log(response)
            showAlert("success", "Success: Check your email")
            fadeOutAlertWithParam(timerRef, 5000)
            setWorking(false)
            setSuccessMsg("An email has been sent to the email provided. Click on the link in the email to continue.")

        }).catch(function (e) {
            showAlert("danger", "Error. Try after 5 minutes.")
            console.log(e)
        })
    }

    const onSubmit = async (data) => {
        //alert(JSON.stringify(data))
        setSuccessMsg(null)
        showAlert("info", "Working...")
        sendEmail(data)
    }



    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(ResetPwSchema)
    })
    return (
        <div className=' mt-6'>
            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Reset Password"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' h-[32px]'></div>

            <div className=' border-[1px] border-gray-500/30 rounded-[10px] px-[16px] py-[22px]'>

                <div className=' flex gap-3 font-bold text-xl mb-3'>

                    <div className=' text-red-700 text-2xl'>
                        Password Change
                    </div>
                </div>
                <div className=' text-[15px]'>

                    Ensure your password has at least 8 alphanumeric characters including any <b><u>uppercase</u></b> letter ( starting from A to Z), any <b><u>lowercase</u></b> letter (starting from a to z), any number (0 to 9) and any and/or combination of a special character including $ % ^ & * ( ) $ £ @ !.
                </div>


            </div>

            <div className=' mt-8'>
                <div className="">
                    <div className=" font-bold text-xl mb-2">Send Password Reset Email.</div>
                    <div>
                        You are about to send a password link to your email below. When ready, click the <b>"Send Password Email"</b> button below, then check your email.
                    </div>
                </div>
            </div>


            <div className=' mt-4'>
                <form id='cef' action={REACT_APP_API_URL} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className=' mt-6 flex flex-col space-y-4'>

                            <input
                                {...register("email", {
                                    onChange: changeHandler
                                })}
                                className=' bg-gray-100 border-[1px] border-gray-400/50 h-[35px] rounded-[8px] px-4  text-[17px] w-[50%] bg-gray-500/30 font-bold'
                                disabled={"disabled"}
                                type="text"
                            />
                            {errors.email && <div className='text-red-400 mt-[5px] text-[15px] font-bold'>{errors.email.message}</div>}
                            {errors.root && <div className='text-red-400 mt-[5px] text-[15px] font-bold'>{errors.root.message}</div>}
                            {successMsg &&
                                <div className='text-green-700 mt-[5px] text-[15px] font-bold border-[1px] border-green-800/40 p-[8px] rounded-[8px] w-[50%] bg-green-500/20 leading-5 '>
                                    {successMsg}
                                </div>}

                            <div className='font-normal text-[14px] mt-[8px] leading-[18px]  w-[70%]'>
                                You are about to send a password link to your email below. When ready, click the "Send Password Email" button below, then check your email and click on the reset link.
                            </div>

                            {/* <input type="hidden" name="operation" value="FORGOT_PASSWORD" />
                            <input type="hidden" name="user_id" value={parsedPreloadedValue?.user_id} />
                            <input type="hidden" name="email" value={parsedPreloadedValue?.email} /> */}

                            <div className='flex flex-col w-[100%] mb-6'>

                                <div className=''>
                                    <GreenButton
                                        working={working}
                                        title="Send Email"
                                    />
                                </div>
                            </div>
                        </div>


                    </div>




                </form>
            </div>
        </div >
    )
}