import React, { useContext, useEffect, useState } from 'react'
import { BsClock, BsClockFill, BsClockHistory } from 'react-icons/bs';
import { GlobalContext } from '../../context/GlobalContext';
import axios from 'axios';
import { ClockIcon } from '@heroicons/react/outline';
import { CiClock1, CiClock2 } from 'react-icons/ci';

const GetDatesForSearch = ({ businessData }) => {
    //console.log(businessData)
    const [workDays, setWorkDays] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [day, setDay] = useState("")

    const getWorkDays = async () => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_OPERATING_TIME_FOR_DISPLAY&id=" + businessData?.business_id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data)
                const dataObject = JSON.parse(data)

                //console.log(dataObject)
                setWorkDays(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getWorkDays()
    }, [])

    //alert(JSON.stringify(workDays))
    const getDay = (workDays) => {
        var today = new Date();
        //   multiplied by negative 24
        let currentDate = (today).toDateString(); // will be Monday

        let month = (today.getMonth() + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })
        let oday = today.getDate().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })


        let year = today.getFullYear().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })


        let itemToReturn = ""

        workDays.map((item) => {
            let day = item.day
            let time = item.time
            if (time !== "" && time !== null) {
                if (currentDate.includes(day)) {
                    if (item.time.includes("Closed")) {

                        itemToReturn = <div className=' flex place-content-start place-items-center gap-2'>
                            <div className='w-fit text-[14px] font-bold'>
                                CLOSED NOW
                            </div>
                            {/* <div className=' text-[14px]'>
                                Today: <b>{item.day}</b>, {item.time}
                            </div> */}
                        </div>
                    } else {

                        if (item.time.length >= 5) {

                            let hourAdjustment = 0
                            if (businessData?.timezone !== null && businessData?.timezone !== "") {
                                hourAdjustment = businessData?.timezone * 1
                            }
                            hourAdjustment = hourAdjustment
                            let date = new Date();
                            //let timezoneOffset = date.getTimezoneOffset();
                            let pstOffset = hourAdjustment * 60;
                            let adjustedTime = new Date(date.getTime() + (pstOffset) * 60 * 1000);

                            let currTime = adjustedTime.getTime()


                            let lastSegmentOfTime = item.time.substring(0, item.time.length - 3)
                            let secondSegment = lastSegmentOfTime.substring(lastSegmentOfTime.length - 5)
                            let hourInfo = secondSegment.split(":")

                            let closingHour = hourInfo[0].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })
                            let closingMinute = hourInfo[1].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            })

                            let closingTimeStr = year + "-" + month + "-" + oday + "T" + (closingHour) + ":" + closingMinute + ":" + "00"
                            let closingOptions = {}
                            let closingTime = new Date(closingTimeStr).getTime()


                            let openingSegmentOfTime = item.time.substring(0, 5)
                            let secondSegmentOpening = openingSegmentOfTime.split(":")
                            let openingHour = secondSegmentOpening[0].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            });
                            let openingMinute = secondSegmentOpening[1].toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false
                            });

                            let openingTimeStr = year + "-" + month + "-" + oday + "T" + (openingHour) + ":" + openingMinute + ":" + "00"
                            let openingTime = new Date(openingTimeStr).getTime()


                            let diffOpening = currTime - openingTime
                            let diffClosing = currTime - closingTime

                            //console.log(diffOpening + "=" + diffClosing + "=" + businessData?.title)



                            if (diffOpening > 0 && diffClosing < 0) {
                                itemToReturn = <div className=' flex place-content-start place-items-center gap-[3px]'>
                                    <div className=''>
                                        <CiClock2 className=' text-[14px] relative top-[-2px] font-bold' />
                                    </div>
                                    <div className='w-fit text-[14px] font-bold'>
                                        OPEN NOW
                                    </div>
                                    {/* <div className=' text-[14px]'>
                                        Today: <b>{item.day}</b>, {item.time}
                                    </div> */}
                                </div>
                            } else {
                                itemToReturn = <div className=' flex place-content-start place-items-center gap-[3px]'>
                                    <div className=''>
                                        <CiClock2 className=' text-[14px] relative top-[-0.097em] font-bold' />
                                    </div>
                                    <div className='w-fit text-[14px] font-bold'>
                                        CLOSED NOW
                                    </div>
                                    {/* <div className=' text-[14px]'>
                                        Today: <b>{item.day}</b>, {item.time}
                                    </div> */}
                                </div>
                            }
                        }
                    }
                    //alert(lastSegmentOfTime)
                }
            }
        })

        return itemToReturn
    }

    useEffect(() => {
        if (workDays !== null) {
            let d = getDay(workDays)
            setDay(d)
        }
    }, [workDays])

    return (
        <div className='flex justify-end'>
            {day !== null && day}
        </div>
    )
}

export default GetDatesForSearch
