import React from 'react'
import { CgSpinner } from 'react-icons/cg'

const GreenButton = (props) => {
    return (
        <button href={props.url}
            className={`${props.working ? `'
            hover:bg-gray-700/20
            bg-gray-700/30
            w-auto px-4
            text-black/70 pt-[8px]
            pb-[10px]
            rounded-full text-[17px]
            font-normal 
            border-none
            '`:
                `
            hover:bg-blue-700
            bg-blue-800 w-auto px-4          
            text-white pt-[8px] pb-[10px]
            rounded-full text-[17px]
            font-bold border-[1px]
            shadow-blue-400
            `}`}
        >

            {props.working ?
                <div className=" flex space-x-2 place-content-center place-items-center">
                    <CgSpinner size={20} className=" animate-spin" /> Working...</div>
                : props.title}
        </button>
    )
}

export default GreenButton
