import { React, useState, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import MiddleMenu from '../MiddleMenu'
import Logo from '../Logo'
import Hamburger from '../Hamburger'
import HeaderSearch from './HeaderSearchForm'
import UserDropDown from '../UserDropDown'
import HeaderSearchForm from './HeaderSearchForm'


const routeConfig = [

]

function HeaderLightSearch({
    bgColor, bgColorOnScroll, logoColor,
    logoColorOnScroll, logoBgColor, logoBgColorOnScroll, color,
    colorBorder, colorOnScroll, borderOnScroll,
    borderColorOnHover, logoTextColor, logoTextColorOnScroll }) {
    const router = useRoutes(routeConfig)

    const [isScrolling, setisScrolling] = useState(false);
    const [defaultBg, setDefaultBg] = useState("light")
    const [defaultTxt, setDefaultTxt] = useState()

    const TOP_OFFSET = 2;
    useEffect(() => {
        const handleScroll = () => {

            if (window.scrollY >= TOP_OFFSET) {
                setisScrolling(true)
            } else {
                setisScrolling(false)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [setisScrolling]);

    useEffect(() => {
        setDefaultBg(bgColor)
    }, [bgColor, setDefaultBg])


    return (
        <div>
            <div className={`fixed
            w-full
            top-0 z-10 transition duration-10
            ${isScrolling ? bgColorOnScroll : bgColor} 
            border-b-[1px] border-gray-500/20
             pt-3 pb-3 z-[11]
            ${isScrolling && 'shadow-md'}
            `}>


                <div className='px-3'>
                    <div className=' max-w-[1200px] mx-auto w-full 
                    
                    '>
                        <header className=' relative flex items-center justify-between 
                        
                        '>
                            <div className='relative top-[0px] '>
                                <Logo
                                    isScrolling={isScrolling}
                                    colorInfo={logoColor}
                                    colorOnScroll={logoColorOnScroll}
                                    logoTextColor={logoTextColor}
                                    logoTextColorOnScroll={logoTextColorOnScroll}
                                    logoBgColor={logoBgColor}
                                    logoBgColorOnScroll={logoBgColorOnScroll}
                                    fullLogoOnMobile={false}
                                />
                            </div>
                            <div className=' relative w-full  '>
                                <HeaderSearchForm />
                            </div>
                            <div className='hover:cursor-pointer flex justify-center'>

                                <div className='hidden md:block'>
                                    <UserDropDown />
                                </div>
                                <div className=' flex place-items-center relative top-[4px]'>
                                    <Hamburger isScrolling={isScrolling} color={color} colorOnScroll={colorOnScroll} />
                                </div>
                            </div>
                        </header>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderLightSearch
