import React, { useEffect } from 'react'
import { useState, useContext } from 'react'
import { SearchIcon } from '@heroicons/react/outline'

import ResetPwSchema from './ResetPwSchema'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import { BiLogIn } from 'react-icons/bi'
import { IoSettingsOutline } from 'react-icons/io5'
import { MdOutlinePassword } from 'react-icons/md'
import { CgPassword, CgSpinner } from 'react-icons/cg'
import { GiPadlock } from 'react-icons/gi'
import AlertContext from '../../context/AlertContext'
import { useForm, input } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Link } from 'react-router-dom'
import { Global } from '@emotion/react'
import { UtilContext } from '../../context/UtilContext'
import axios from 'axios'


const initialValues = {
    email: "",
    password: ""
}

function ResetPwForm() {
    const parsedPreloadedValue = []
    const [working, setWorking] = useState(false)
    const { showAlert, timerRef, closeAlert, fadeOutAlert,
        fadeOutAlertWithParam
    } = useContext(AlertContext)
    const [successMsg, setSuccessMsg] = useState(null)
    const [formData, setFormData] = useState(parsedPreloadedValue)
    const { REACT_APP_API_URL, REACT_APP_HOST } = useContext(GlobalContext)
    const { getHostPortProtocol } = useContext(UtilContext)


    const sendEmail = async (data) => {
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let endpoint = REACT_APP_API_URL + "?operation=FORGOT_PASSWORD"
        data["app_host"] = REACT_APP_HOST //getHostPortProtocol(window)

        let rst = axios.post(endpoint, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (response) {

            showAlert("success", "Success: Check your email")
            fadeOutAlertWithParam(timerRef, 5000)
            setWorking(false)
            setSuccessMsg("A message has been sent to the email provided. Click on the link in the email to continue.")

        }).catch(function (e) {
            showAlert("danger", "Error. Try after 5 minutes.")
            console.log(e)
            setWorking(false)
        })

    }
    const onSubmit = (data) => {
        setSuccessMsg(null)
        setWorking(true)
        showAlert("info", "Working...")
        sendEmail(data)
    }

    const changeHandler = (e) => {
        let value = e.target.value
        let name = e.target.name
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: (parsedPreloadedValue),
        resolver: zodResolver(ResetPwSchema)
    })

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className=' flex flex-col place-content-center bg-white h-[100vh]'>



                    <div className=' w-full'>
                        <div className=' text-center font-black 
                        text-black text-[25px] mt-[20px] mb-[0px]
                        tracking-tighter
                        '>
                            Reset Password
                        </div>
                        <div className=' text-center'>
                            Don't have an account? <Link to={"/signup"} className=' underline font-bold'>Sign up</Link>
                        </div>


                        <div className=' text-center'>
                            <Link to={"/login"} className=' underline font-bold'>Login</Link>
                        </div>


                        <div className=' max-w-[1200px] mx-auto w-full mt-4
                         flex place-content-center place-items-center
                        '>

                            <div className=' 
                            flex justify-center place-items-center
                            gap-1 flex-col w-[250px]
                            
                            '>
                                <input
                                    {...register("email", {
                                        onChange: changeHandler
                                    })}
                                    type="text"
                                    name="email"
                                    placeholder='Enter Email Address'
                                    className=' text-[17px] w-full px-3 
                                    outline-none py-[5px] border-[1px] rounded-[8px]
                                    border-gray-500/30  bg-blue-50
                                    '

                                />



                                <button
                                    className=' 
                                    bg-green-800 py-[8px] w-full
                                    flex justify-center 
                                    place-items-center
                                    rounded-[8px]
                                    '
                                    type='submit'
                                >
                                    {
                                        working ?
                                            <CgSpinner className=' w-8 h-7 text-white animate-spin' />
                                            : <GiPadlock className=' w-8 h-7 text-white' />
                                    }
                                </button>

                                {
                                    errors.email &&
                                    <div className='text-white mt-[-1px] text-[15px] font-bold
                                    pt-3 pb-4 bg-red-500 w-full rounded-[4px] text-center
                                    '>
                                        {errors.email.message}
                                    </div>
                                }
                                {errors.root && <div className='text-red-400 mt-[5px] text-[15px] font-bold'>{errors.root.message}</div>}
                                {successMsg &&
                                    <div className='border-[1px] border-green-800/40 p-[8px] rounded-[8px] w-full
                                    bg-green-500/20 flex flex-wrap'>
                                        <div className='font-bold mb-[3px]'>Success</div>
                                        <div className='text-black text-[15px]  
                                      leading-5 '>
                                            {successMsg}
                                        </div>
                                    </div>
                                }
                            </div>



                        </div>

                    </div>



                </div>


            </form>

        </div >
    )
}

export default ResetPwForm
