import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext, useGlobal } from '../../context/GlobalContext'
import { Link, useRoutes } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const activeTab = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "red" };
    }
};


const navjson = [
    {
        id: 1,
        title: "Home",
        link: "/",
    },
    {
        id: 2,
        title: "Search",
        link: "/search",
    },
    {
        id: 3,
        title: "Contact us",
        link: "/contact",
    },
    {
        id: 4,
        title: "Advertise",
        link: "/advertise",
    },
    {
        id: 5,
        title: "Privacy",
        link: "/privacy",
    },
]

function MiddleMenu({ router, isScrolling, color, colorBorder, colorOnScroll, borderOnScroll }) {
    const { horizontalNav } = useContext(GlobalContext)
    const [activeLink, setActive] = useState(false)

    const location = useLocation();
    const { hash, pathname, search } = location




    return (
        <div>
            <div className='flex space-x-10'>

                {
                    navjson.map(function (item) {
                        let activatedLink = false;

                        if (pathname === item.link) {
                            activatedLink = true;

                        } else {
                            activatedLink = false;
                        }
                        return (
                            <a key={item?.link} href={item.link}>
                                <span
                                    key={item?.id}

                                    exact="true"
                                    className={`${activatedLink ? `${(isScrolling) ? borderOnScroll : colorBorder} font-bold` : ''} ${isScrolling ? colorOnScroll : color} relative rounded-none  hover:border-b-white/50 hover:text-gray-500  pt-[2px] pb-[0px]  cursor-pointer text-[14px]`}
                                    /*  className={({ isActive, isPending, isTransitioning }) =>
                                         [
                                             isPending ? "pending" : "",
                                             isActive ? " border-b-[1px] border-b-white/60" : "",
                                             isTransitioning ? "transitioning" : "",
                                         ].join(" text-white relative rounded-none hover:text-white/50 hover:border-b-white/50 hover:text-white  pt-[2px] pb-[0px]  cursor-pointer text-[14px]")
                    
                                     } */
                                    onClick={() => {
                                        window.location.href = item.link
                                    }}

                                >
                                    {item?.title}
                                </span>
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MiddleMenu
