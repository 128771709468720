import React, { useContext, useEffect } from 'react'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import H16 from '../../components/body/H16'
import ResetPwdForm from './ResetPwdForm'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'

const ResetPwdLanding = () => {
    const { gcolors } = useContext(GlobalContext)

    return (
        <div className=' relative'>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />




            <div className='  w-full h-screen place-content-center place-items-center '>
                <div className=' text-center font-black text-black text-[26px] tracking-tightr mt-[20px] mb-[0px]'>
                    Reset Password
                </div>
                <div className=' text-center text-lg'>
                    Create new password below!
                </div>
                <div className=' max-w-[1200px] mx-auto w-full space-y-7'>

                    <ResetPwdForm />

                    <div className=' text-center text-[13px] font-normal space-y-[-3px]'>
                        <div>Go international. Be visible with Skyepages.</div>
                        <div>Contact us for more information and customized updates.</div>
                    </div>

                </div>



            </div>



        </div>
    )
}

export default ResetPwdLanding
