import React, { useContext, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import GrayButton from '../../components/GrayButton'
import H32 from '../../../../components/body/H32'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const DeactivateContent = ({ userDetails, user }) => {
    const userData = JSON.parse(userDetails)
    const [deactivateText, setDeactivateText] = useState('Deactivate')
    const [activateText, setActivateText] = useState('Activate')
    const [deactivateState, setDeactivateState] = useState(false)
    const [activateState, setActivateState] = useState(false)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const navigate = useNavigate()
    const [msg, setMsg] = useState()

    const deactivateUser = async (userId) => {

        setDeactivateText('Working...')
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let endpoint = REACT_APP_API_URL + "?operation=DEACTIVATE_USER"
        endpoint += "&user_id=" + userId

        const data = new FormData()
        data.append('user_id', userId)

        let rsp = await axios.post(endpoint, data)
            .then(response => {
                //setMsg(response.data)
                navigate(0)
            })
            .catch(error => setMsg(error))
    }

    const activateUser = async (userId) => {
        setActivateText('Working...')
        await new Promise((resolve) => setTimeout(resolve, 1000));
        let endpoint = REACT_APP_API_URL + "?operation=ACTIVATE_USER"
        endpoint += "&user_id=" + userId
        const data = new FormData()
        data.append('user_id', userId)

        let rsp = await axios.post(endpoint, data)
            .then(response => {
                //setMsg(response.data)
                navigate(0)
            })
            .catch(error => setMsg(error))
    }

    return (
        <div className=' mt-6'>
            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Account Profile"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <H32 />

            <div className=' p-[15px] pb-[32px] rounded-[8px] border-[1px] border-b-gray-500/30'>
                <div className='font-bold mb-[12px] text-xl'>{userData?.active == true ? deactivateText : activateText} {userData?.title}</div>
                <div className=' mb-2'>You are about to deactivate your account. To continue click "Deactivate" button below.</div>

                <div className=' mt-5 mb-5'>
                    <ul className=' list-outside list-disc ml-5 space-y-2'>
                        <li>
                            <div className=' font-semibold'>
                                Visible in emails
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    When deactivated, your account will cease to appear in any of Skyepages account in future.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div className=' font-semibold'>
                                Receives notifications
                            </div>
                            <ul className=' list-outside'>
                                <li>
                                    This account will cease to receive notifications via emails in future. Those with this account's email can still send emails to this account's emal address.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className=' border-b-[1px] border-gray-500/30 mb-[32px] '></div>
                <div>
                    {
                        userData?.active == true && !deactivateState && <button

                            onClick={
                                () => setDeactivateState(true)
                            }
                            className="hover:bg-gray-300/30
                            bg-gray-200 px-4 pt-[2px]
                            pb-[4px] rounded-[6px]
                            border-[1px] border-gray-400
                            text-[14px]
}">
                            Deactivate
                        </button>

                    }

                    {
                        userData?.active == true && deactivateState &&
                        <button

                            onClick={
                                () => setDeactivateState(false)
                            }
                            className="hover:bg-gray-300/30
                            bg-gray-200 px-4 pt-[2px]
                            pb-[4px] rounded-[6px]
                            border-[1px] border-gray-400
                            text-[14px]">
                            Cancel
                        </button>
                    }

                    {
                        userData?.active != true && !activateState && <button

                            onClick={
                                () => setActivateState(true)
                            }
                            className="hover:bg-gray-300/30
                            bg-gray-200 px-4 pt-[2px]
                            pb-[4px] rounded-[6px]
                            border-[1px] border-gray-400
                            text-[14px]">
                            Activate
                        </button>
                    }

                    {
                        userData?.active != true && activateState &&
                        <button

                            onClick={
                                () => setActivateState(false)
                            }
                            className="hover:bg-gray-300/30
                            bg-gray-200 px-4 pt-[2px]
                            pb-[4px] rounded-[6px]
                            border-[1px] border-gray-400
                            text-[14px]">
                            Cancel
                        </button>
                    }

                </div>


                {
                    deactivateState && <div className=' transition duration-1000 mt-[32px] h-auto flex flex-col space-y-8 place-content-center place-items-center text-center border-[1px] border-gray-500/20 rounded-[8px] px-5 py-[32px]'>
                        <div className=' font-bold text-xl border-[1px]  w-full p-2 bg-gray-200 rounded-[8px]'>
                            Deactivating User...
                        </div>
                        <div>
                            You are about to deactivate your account. You can activate it in future. However, when you deactivate your account, it will no longer be visible to public viewers. Those that have email address for this account can still send email to the email address. To continue, click the "Deactivate" button below.
                        </div>
                        <div className=' border-b-[1px] border-gray-500/50 w-full'></div>

                        <div>
                            <button

                                onClick={
                                    () => {
                                        const choice = window.confirm("You are about to deactivate this business. Click yes to proceed or Cancel to abort.")

                                        if (choice == true) {
                                            deactivateUser(user.user_id)
                                        } else {
                                            setDeactivateState(false)
                                        }
                                    }
                                }
                                className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                                {deactivateText}
                            </button>
                        </div>
                    </div>
                }


                {
                    activateState && <div className=' mt-[32px] h-auto flex flex-col space-y-8 place-content-center place-items-center text-center border-[1px] border-gray-500/20 rounded-[8px] px-4 pb-[32px] pt-5'>
                        <div className=' font-bold text-xl border-[1px]  w-full p-2 bg-gray-200 rounded-[8px]'>
                            Activating User...
                        </div>
                        <div>
                            You are about to activate your account. Once activated, your account, will be visible to public viewers. Those that have email address for this account can send email to this account's email address. To continue, click the "Activate" button below.
                        </div>
                        <div className=' border-b-[1px] border-gray-500/50 w-full'></div>
                        <div>
                            <button

                                onClick={
                                    () => {
                                        const choice = window.confirm("You are about to activate this business. Click yes to proceed or Cancel to abort.")

                                        if (choice == true) {
                                            activateUser(user.user_id)
                                        } else {
                                            setActivateState(false)
                                        }
                                    }
                                }
                                className="hover:bg-gray-300/30 
                            bg-gray-200 px-4 pt-[2px] 
                            pb-[4px] rounded-[6px] 
                            border-[1px] border-gray-400 
                            text-[14px]">
                                {activateText}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DeactivateContent
