import React from 'react'

const Intro = () => {
    return (
        <>
            <div className=' px-5'>
                <div className=' max-w-[1200px] w-full mx-auto'>
                    <div className=' flex flex-col place-items-center'>
                        <div className=' font-black text-[25px] md:text-[35px] tracking-tighter space-x-2 md:space-x-3 text-center leading-[1em]'>
                            <span>Get</span>
                            <span>Listed</span>
                            <span>on</span>
                            <span>Storcs</span>
                            <span>Free.</span>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Intro
