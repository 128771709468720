import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import { UtilContext } from '../../context/UtilContext'

const ReviewCount = ({ businessId }) => {
    const [reviews, setReviews] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const { shortenNumbers } = useContext(UtilContext)

    const getReviewData = (businessId) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_REVIEWS_BY_BUSINESS_ID&business_id=" + businessId
        //window.location.href = (endpoint)
        //return false

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res)
                const data = JSON.stringify(res.data.data[0])
                //alert(data)
                setReviews((JSON.parse(data)))

                //alert(businessData)
            }).catch((res) => {
                console.log(res)
                //alert("here")
            })
    }

    useEffect(() => {
        getReviewData(businessId)
    }, [])

    useEffect(() => {
        if (reviews !== null) {
            ///alert((reviews.total))
        }
    }, [reviews])

    return (
        <div>
            <a href="#">
                <div className=' flex place-items-center underline '>
                    <span>
                        <b>Reviews:</b>
                    </span>
                    <span className=''>
                        &nbsp;{shortenNumbers(reviews?.total)}
                    </span>
                </div>
            </a>
        </div>
    )
}

export default ReviewCount
