import React, { useEffect, useState } from 'react'



const TextAreaCustom = ({ register, changeHandler, getValues, controlName, maximumWords, height, labelTop, labelBottom, error }) => {
    const [wordCount, setWordCount] = useState()
    const [maxWords] = useState(maximumWords)

    const handleKeyDown = (e) => {

        if (wordCount == maxWords) {
            // check for spacebar, enter and control
            if (e.keyCode == 32 || e.keyCode == 13 || e.keyCode == 17) {
                e.preventDefault()
            }



            if ((e.ctrlKey || e.metaKey) && (e.keyCode == 86)) {
                // check for control + v
                e.preventDefault()
            }

            if (e.ctrlKey || e.metaKey) {
                e.preventDefault()
            }


            //alert(e.keyCode)
        }
    }

    const getMaxWords = (controlName) => {
        let text = document.getElementById(controlName).value;

        let reconstruct = ""
        let wordsLength = 0
        let testWordLength = text.length

        if (testWordLength > 0) {

            let trimmedText = text.trim()

            let words = trimmedText.split(" ")
            wordsLength = words.length


            for (let i = 0; i < maxWords; i++) {
                let word = words[i]

                if (i < wordsLength) {
                    if (i == (maxWords - 1)) {
                        reconstruct += word
                    } else {
                        reconstruct += word + " "
                    }
                }
            }
        }


        document.getElementById(controlName).value = reconstruct
        let displayText = wordsLength + " words."
        displayText += (wordsLength > maximumWords) ? " Excess words will be removed." : ""

        document.getElementById("counterDisplay").innerHTML = displayText
    }

    useEffect(() => {//set text area to null
        var textarea = document.getElementById(controlName)
        if (getValues(controlName) === null || getValues(controlName) === '') {
            if (textarea) {
                textarea.value = '';
            }
        }
    }, [])

    useEffect(() => {//get only maximum number of words and discard the rest
        new Promise((resolve) => setTimeout(resolve, 1000));
        getMaxWords(controlName)
    }, [controlName])

    const WordCount = (e, inputId, outputId) => {

        let previousCharacter = ""


        // Get the input text value
        let text = document.getElementById(inputId).value;


        // Initialize the word counter
        let numWords = 0;

        // Loop through the text
        // and count spaces in it 
        for (let i = 0; i < text.length; i++) {
            let currentCharacter = text[i];

            //console.log(currentCharacter)
            // check previous character
            if (previousCharacter == "" && currentCharacter != " ") {
                numWords += 1
                previousCharacter = currentCharacter
            }

            if (previousCharacter != "" && currentCharacter == " ") {
                // do nothing
                //console.log("criteria 1")
                previousCharacter = currentCharacter
            }

            if (currentCharacter == " ") {
                // do nothing
                //console.log("criteria 2")
                previousCharacter = currentCharacter
            }

            if (currentCharacter != " ") {
                if (previousCharacter == " ") {

                    //console.log("criteria 3")
                    numWords += 1
                    previousCharacter = currentCharacter
                }
            }

            if (currentCharacter == " ") {
            }
        }

        setWordCount(numWords)

        let displayText = numWords + " words. "
        displayText += (numWords > maximumWords) ? "Excess will be removed." : ""

        // Display it as output
        document.getElementById(outputId).innerHTML = displayText;
    }



    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='form-labels' htmlFor="title">{labelTop}</label>


                <div className=" border-[1px] border-gray-400/50 rounded-[8px] pt-1 pb-0 font-normal text-[17px] w-full">
                    <div className=" border-b-[1px] border-gray-500/40 p-[8px]">
                        Allows a maximum of {maximumWords} words. <b id="counterDisplay"></b>
                    </div>
                    <textarea
                        {...register(controlName, {
                            onChange: changeHandler
                        })}
                        id={controlName}
                        type='text'
                        className={`bg-gray-100 rounded-[8px] font-normal text-[17px] w-full h-[${height}px] p-[17px]`}
                        placeholder='Enter business introduction'
                        onKeyUp={(e) => {
                            WordCount(e, controlName, "counterDisplay")
                        }}
                        onMouseEnter={(e) => handleKeyDown(e)}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onBlur={(e) => {
                            getMaxWords(controlName)
                            WordCount(e, controlName, "counterDisplay")

                        }}


                    ></textarea>
                </div>

                {error && (<div className=' text-red-400 mt-[5px] text-[15px]'><span className=' text-red-500 font-bold'>{error.message}</span></div>)}

                <label className='label-description'>
                    {labelBottom}
                </label>
            </div>


        </div>
    )
}

export default TextAreaCustom
