import React from 'react'

const InputCustom = ({ register, changeHandler, controlName, type, disabled, maximumLength, width, labelTop, labelBottom, placeholder, error }) => {
    return (
        <div>
            <div className='flex flex-col w-[100%] mb-6 '>
                <label className='font-bold mb-[8px]' htmlFor="title">{labelTop}</label>
                <input
                    {...register(controlName, {
                        onChange: changeHandler
                    })}
                    className={`bg-gray-100 border-[1px] ${disabled == 'disabled' && 'bg-gray-300'} border-gray-400/50 h-[40px] rounded-[5px] px-4 font-normal text-[17px] w-[${width}%]`}
                    type={type}
                    placeholder={`${placeholder}`}
                    maxLength={maximumLength}
                    disabled={`${disabled == 'disabled' ? disabled : ''}`}
                />

                {error && (<div className=' text-red-400 mt-[5px] text-[15px]'><span className=' text-red-500 font-bold'>{error.message}</span></div>)}

                <label className='font-normal text-[14px] mt-[8px] leading-[18px]'>
                    {labelBottom}
                </label>


            </div>
        </div>
    )
}

export default InputCustom
