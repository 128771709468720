import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../../context/GlobalContext'
import axios from 'axios'

let selectedName = ''
let selectedCodeHidden = ''

const CountryStateCityCompSlave = ({
    register,
    changeHandler,
    setValue,
    setValueHandler,
    getValues,
    controlName,
    controlId,
    controlWrapper,
    controlNameHidden,
    controlIdHidden,
    controlPlaceholder,
    controlDisabled,
    controlMaxLen,
    inputType,
    width
}) => {
    const [results, setResults] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)


    const fetchData = async (value, stateCode) => {

        let endpoint = REACT_APP_API_URL + "?operation=GET_STATES"
        endpoint += "&param=" + value
        endpoint += "&country_id=" + stateCode
        await new Promise((resolve) => setTimeout(resolve, 500));

        //window.location.href = endpoint
        //return false

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            //alert(result)
            console.log(result)
            const data = (result.data.data)
            setResults(data)
        }).catch((result) => {
            console.log(result)
        })
    }
    const loadData = async (value) => {
        const stateCode = getValues("cntycode")

        if (stateCode !== null) {
            await fetchData(value, stateCode)
        }
    }

    useEffect(() => {

        if (controlNameHidden) {
            //alert(getValues(controlNameHidden))
        }
    }, [controlNameHidden])



    const inputStyle = `bg-gray-100 border-[1px] ${controlDisabled == 'disabled' && 'bg-gray-300'} border-gray-400/50 h-[35px] rounded-[4px] px-4 font-normal text-[17px] w-[${width}%]`
    return (
        <div className=' relative'>
            <div className=' relative'>
                <input
                    {...register(controlNameHidden, {

                    })}
                    type={"hidden"}
                    id={controlIdHidden}
                    onChange={(e) => {
                        changeHandler(e)
                        //setValue(controlNameHidden, e.target.value)

                    }}

                    defaultValue={""}
                />
                <input
                    {...register(controlName)}
                    id={controlId}
                    className={inputStyle}
                    type={inputType}
                    placeholder={`${controlPlaceholder}`}
                    maxLength={controlMaxLen}
                    disabled={`${controlDisabled == 'disabled' ? controlDisabled : ''}`}
                    onKeyUp={(e) => loadData(e.target.value)}
                    onFocus={(e) => {
                        const resultslist = document.getElementById(controlWrapper)
                        //selectedName = ''
                        //selectedCodeHidden = ''

                        if (resultslist) {
                            resultslist.style.display = 'block'
                        }
                    }}
                    onChange={(e) => {
                        changeHandler(e)
                        //setValue(controlName, e.target.value)

                    }}
                    onBlur={async (e) => {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        //alert(getValues(controlNameHidden))
                        changeHandler(e)
                        setValue(controlName, e.target.value)

                        if (selectedName == '' && results !== null) {

                            document.getElementById(controlId).value = results[0]?.name
                            document.getElementById(controlIdHidden).value = results[0]?.iso2

                            await new Promise((resolve) => setTimeout(resolve, 500));
                            changeHandler(e)
                            setValue(controlName, results[0]?.name)
                            setValue(controlNameHidden, results[0]?.iso2)
                            document.getElementById(controlWrapper).style.display = 'none'
                            selectedName = results[0]?.name
                            selectedCodeHidden = results[0]?.iso2
                            /* alert('a')
                            document.getElementById(controlId).value = e.target.value
                            await new Promise((resolve) => setTimeout(resolve, 500));
                            changeHandler(e)
                            setValue(controlName, results[0].name) */
                        } else {
                            let val = document.getElementById(controlId).value
                            const res = results?.filter((item) => {
                                return (
                                    item?.name.toLowerCase().includes(val.toLowerCase())
                                )
                            })

                            if (res?.length > 0) {
                                document.getElementById(controlId).value = res[0]?.name
                                await new Promise((resolve) => setTimeout(resolve, 600));
                                changeHandler(e)
                                setValue(controlName, res[0].name)
                                setValue(controlNameHidden, res[0].iso2)

                                selectedName = res[0].name
                                selectedCodeHidden = res[0].iso2
                            }
                            document.getElementById(controlWrapper).style.display = 'none'
                        }
                        //alert(JSON.stringify(results[0].name))
                    }}
                />
            </div>
            {results && results.length > 0 &&
                <div className='relative'>
                    <SearchResultsList
                        setValue={setValue}
                        setValueHandler={setValueHandler}
                        results={results}
                        controlName={controlName}
                        controlNameHidden={controlNameHidden}
                        controlId={controlId}
                        controlIdHidden={controlIdHidden}
                        controlWrapper={controlWrapper}
                    />
                </div>
            }
        </div>
    )
}

export const SearchResultsList = ({ results, setValue, setValueHandler, controlName, controlNameHidden, controlId, controlIdHidden, controlWrapper }) => {
    return (
        <div id={controlWrapper} className=' w-[100%] absolute top-[-2px] z-30 bg-white flex flex-col border-[1px] border-gray-500 shadow-md rounded-b-[10px] mt-[5px] max-h-[300px] overflow-y-auto'>
            {
                results.map((result, id) => {
                    return (
                        <SearchResult
                            setValue={setValue}
                            setValueHandler={setValueHandler}
                            result={result}
                            key={id}
                            controlName={controlName}
                            controlNameHidden={controlNameHidden}
                            controlId={controlId}
                            controlIdHidden={controlIdHidden}
                            controlWrapper={controlWrapper}
                        />
                    )
                })
            }
        </div>
    )
}

export const SearchResult = ({ result, setValue, setValueHandler, controlName, controlNameHidden, controlId, controlIdHidden, controlWrapper }) => {

    return (
        <div
            className=' px-[20px] cursor-pointer py-[10px] hover: bg-[#efefef]
             '
            onClick={async (e) => {
                document.getElementById(controlId).value = result.name
                document.getElementById(controlIdHidden).value = result.iso2
                await new Promise((resolve) => setTimeout(resolve, 100));
                //alert(result.iso2)
                setValue(controlName, result.name)
                setValue(controlNameHidden, result.iso2)
                setValueHandler(controlNameHidden, result.iso2)
                document.getElementById(controlWrapper).style.display = 'none'
                selectedName = result.name
                selectedCodeHidden = result.iso2
            }}
            id='search-result'
        >
            {result.name}
        </div>
    )
}


export default CountryStateCityCompSlave
