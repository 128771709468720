import { useContext, useState } from "react"
import HeaderLightSearch from "../../../../components/header/headerLight/HeaderLightSearch"
import HeaderSpace from "../../../../components/header/HeaderSpace"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import SettingsLeftNav from "../../components/SettingsLeftNav"
import SubHeaderNav from "../../components/SubHeaderNav"
import { AddBusinessContent } from "./AddBusinessContent"
import Prefooter from "../../../../components/footer/Prefooter"
import Footer from "../../../../components/footer/Footer"

const AddBusiness = () => {
    const { user, REACT_APP_API_URL, gcolors } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [businessData, setBusinessData] = useState(null)
    const navigate = useNavigate()

    const [id, setId] = useState("")

    const urlid = () => {
        const url = window.location.href;
        let lastUrlSegmentWithSlash = url.substring(url.lastIndexOf("/"));
        let lastUrlSegment = lastUrlSegmentWithSlash.split("/")
        const item_id = lastUrlSegment[1];
        setId(item_id)

    }











    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />
            <div className="mx-5">
                <div className=' max-w-[1200px] mx-auto w-full grid lg:grid-cols-12 gap-8'>
                    <div className='lg:col-span-3 hidden lg:block'>
                        <SettingsLeftNav />
                    </div>
                    <div className=' lg:col-span-9'>



                        <AddBusinessContent
                            preloadedValues={businessData}
                            user={user}
                            businessId={id}
                        />
                    </div>
                </div>
            </div>
            <HeaderSpace />
            <Prefooter />
            <Footer />
        </div>
    )
}

export default AddBusiness