import React, { useContext, useEffect, useState } from 'react'
import H32 from '../../components/body/H32'
import H16 from '../../components/body/H16'
import H24 from '../../components/body/H24'
import { BusinessContext } from './BusinessContext'
import { GlobalContext } from '../../context/GlobalContext'
import axios from 'axios'
import { LuWine } from 'react-icons/lu'
import { GrCafeteria, GrLounge } from 'react-icons/gr'
import { BiLibrary } from 'react-icons/bi'
import { BsMusicNote } from 'react-icons/bs'
import { MdLocalLaundryService } from 'react-icons/md'
import { FaDesktop } from 'react-icons/fa'

const FacilitiesDetails = ({ businessId }) => {

    const { businessData } = useContext(BusinessContext)
    const [facilities, setFacilities] = useState(null)
    const { REACT_APP_API_URL } = useContext(GlobalContext)

    const getFacilities = async (data) => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_FACILITIES&id=" + businessId
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])
                const dataObject = JSON.parse(data)

                console.log(dataObject)
                setFacilities(dataObject)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        getFacilities(businessData?.business_id)
    }, [])

    useEffect(() => {
        if (facilities) {
            //alert(facilities?.bar)
        }
    }, [facilities])


    return (
        <div>
            <div className='font-bold text-[21px] tracking-tighter'>
                Facilities
            </div>
            <H16 />

            <div>

                {facilities?.bar &&
                    <div>
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <LuWine /> Bar
                            </div>
                            <div className=' lg:col-span-9 whitespace-pre-line'>
                                {facilities?.bar}
                            </div>
                        </div>
                    </div>
                }


                {facilities?.cafetaria &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <GrCafeteria /> Cafetaria
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.cafetaria}
                            </div>
                        </div>
                    </div>
                }

                {facilities?.library &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <BiLibrary />Library
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.library}
                            </div>
                        </div>
                    </div>
                }

                {facilities?.lounge &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <GrLounge /> Lounge
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.lounge}
                            </div>
                        </div>
                    </div>
                }

                {facilities?.music &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <BsMusicNote /> Music
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.music}
                            </div>
                        </div>
                    </div>
                }
                {facilities?.mini_mart &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <MdLocalLaundryService /> Mini mart
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.mini_mart}
                            </div>
                        </div>
                    </div>
                }

                {facilities?.business_center &&
                    <div>
                        <H24 />
                        <div className=' grid grid-cols-1 gap-x-3 lg:grid-cols-12 text-[14.3px] '>
                            <div className=' font-bold lg:col-span-3 flex place-items-center gap-2'>
                                <FaDesktop /> Business Center
                            </div>
                            <div className=' lg:col-span-9'>
                                {facilities?.business_center}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FacilitiesDetails
