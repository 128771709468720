import { BookOpenIcon } from '@heroicons/react/outline'
import React, { useContext } from 'react'
import { BiBookContent, BiWine } from 'react-icons/bi'
import { FaWineGlass } from 'react-icons/fa'
import { UtilContext } from '../../context/UtilContext'

const ClaimBusiness = ({ businessId }) => {
    const { getUrlId } = useContext(UtilContext)
    return (
        <div className='border-[1px] border-gray-500/30 rounded-[6px] flex flex-col place-items-center place-content-center px-3'>
            <div className=' w-full mt-3'>
                <div className=' text-[17px] w-full flex place-content-start font-bold'>
                    Do you own this business?
                </div>
                <div className=' text-[14px]'>
                    Claim this business.</div>
            </div>
            <div className='bg-blue-500 text-white  my-3 rounded-[4px] w-full max-w-[440px]'>
                <a href={`/claim-business/${getUrlId()}`} className='relative'>
                    <div className='flex place-content-center place-items-center py-[10px] gap-x-1'>
                        <div>
                            <BiBookContent />
                        </div>
                        <div className=' text-[15px]'>
                            Claim this business
                        </div>
                    </div>
                </a>
            </div>

        </div>
    )
}

export default ClaimBusiness
