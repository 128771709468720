import React, { useContext } from 'react'
import Header from '../../components/header/Header'
import HeroAnimated from '../../components/body/HeroAnimated'
import Hero from '../../components/body/Hero'
import Prefooter from '../../components/footer/Prefooter'
import Footer from '../../components/footer/Footer'
import Subtitle from '../../components/body/Subtitle'
import TopIntro from '../../components/body/TopIntro'
import ClientResult from '../../components/body/ClientResult'
import WhatWeDo from '../../components/body/WhatWeDo'
import HowWeCanHelp from '../../components/body/HowWeCanHelp'
import Impact from '../../components/body/Impact'
import Insights from '../../components/body/Insights'
import HorizontalSpce from '../../components/body/HorizontalSpace'

import PostHeroBar from '../../components/body/PostHeroBar'
import H16 from '../../components/body/H16'
import H32 from '../../components/body/H32'
import H64 from '../../components/body/H64'
import Products from '../../components/body/Products'
import PostHero from '../../components/body/PostHero'
import SearchResults from './SearchResult'
import SearchForm from '../../components/body/SearchForm'
import HeaderLight from '../../components/header/headerLight/HeaderLight'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import SubNav from '../business/SubNav'
import SubNavFlex from '../../components/body/subnavflex/SubNavFlex'
import Default from '../../components/header/headerLight/whereto/Default'
import TopBanner from '../../components/ads/TopBanner'
import { GlobalContext } from '../../context/GlobalContext'
import Intro from '../../components/body/Intro'

function Home() {
    const { gcolors } = useContext(GlobalContext)


    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />
            <HeaderSpace />
            <SubNavFlex />

            <H16 />
            <Intro />
            <H16 />

            <TopBanner
                dataAdSlot={'3148685431'}
                dataAdFormat={'auto'}
                dataFullWidthResponsive={true}
            />

            <div className=' px-4'>
                <SearchResults />
                <H64 />
            </div>



            <HeaderSpace />
            {/* <Prefooter /> */}
            <Footer />
        </div>
    )
}

export default Home
