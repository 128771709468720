import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
//import { FaStar as StarIcon } from 'react-icons/fa';
import StarIcon from '@mui/icons-material/Star'
import StarIconOutline from '@mui/icons-material/StarBorderOutlined'
import { ArrowRightIcon } from '@heroicons/react/outline';
import { BsArrowRight, BsChevronBarRight } from 'react-icons/bs';
import { Circle, CircleOutlined, Star, StarBorderOutlined } from '@mui/icons-material';

const labels = {

    1: 'Poor',
    2: 'Fair',
    3: 'Good',
    4: 'Very Good',
    5: 'Exceptional',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function StyledRating({ value, setValue }) {

    const [hover, setHover] = React.useState(-1);


    return (
        <div className=' flex place-content-start place-items-center space-x-2'>
            <span>
                <Rating
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    getLabelText={getLabelText}

                    onChange={(event, newValue) => {
                        setValue(newValue);

                    }}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);

                    }}
                    icon={<Circle style={{ color: "green", fontSize: 40 }} />}
                    emptyIcon={<CircleOutlined style={{ color: "green", fontSize: 40 }} />}

                />
            </span>
            <span>
                {
                    labels[hover !== -1 ? hover : value]
                }
            </span>


        </div>
    );
}