import React from 'react'
import NavButton from './NavButton'
import { FaCarSide } from 'react-icons/fa'
import { TbShoe } from 'react-icons/tb'
import { GiLipstick, GiScales } from 'react-icons/gi'
import { BookOpenIcon, ScaleIcon } from '@heroicons/react/outline'
import { BiBook, BiBookContent, BiMusic, BiRestaurant } from 'react-icons/bi'
import { MdFlight, MdMusicNote, MdPrecisionManufacturing, MdRealEstateAgent } from 'react-icons/md'
import { AiFillInsurance } from 'react-icons/ai'

const navlnk = [
    {
        title: "Automotive",
        icon: <FaCarSide size={18} />,
        link: "/search?what=auto&where="
    },
    {
        title: "Manufacturing",
        icon: <MdPrecisionManufacturing size={18} />,
        link: "/search?what=manufacturing&where="
    },
    {
        title: "Travels",
        icon: <MdFlight size={18} />,
        link: "/search?what=travel&where="
    },
    {
        title: "Legal Services",
        icon: <GiScales size={18} />,
        link: "/search?what=law&where="
    },
    {
        title: "Restaurant",
        icon: <BiRestaurant size={18} />,
        link: "/search?what=restaurant&where="
    },
    {
        title: "Finance",
        icon: <AiFillInsurance size={15} />,
        link: "/search?what=finance&where="
    },
    {
        title: "Entertainment",
        icon: <BiMusic size={15} />,
        link: "/search?what=entertainment&where="
    },
    {
        title: "Real Estate",
        icon: <MdRealEstateAgent size={15} />,
        link: "/search?what=realestate&where="
    },
]

const SubNavFlex = () => {
    return (
        <div className='
            px-5  truncate
            '>
            <div>

                <div className='
                max-w-[1200px] mx-auto w-full
                '>
                    <div className='
                    border-b-[1px] border-gray-500/20
                    flex place-content-between
                    py-2 flex-wrap
                    '>
                        {
                            navlnk.map((item) => {
                                return (
                                    <NavButton
                                        key={item?.link}
                                        title={item?.title}
                                        link={item?.link}
                                        icon={item?.icon}
                                    />
                                )
                            })
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default SubNavFlex
