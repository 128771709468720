import React from 'react'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import SlideCarousel3 from '../../components/body/SlideCarousel3'
import LoginForm from './error404content'
import HeaderSpace from '../../components/header/HeaderSpace'
import Error404content from './error404content'

function Error404() {
    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />



            <Error404content />
        </div>
    )
}

export default Error404
