import { useContext, useEffect, useState } from "react"
import { CgSpinner } from "react-icons/cg"
import { GlobalContext } from "../../../../context/GlobalContext"
import { useNavigate } from "react-router-dom"
import AlertContext from "../../../../context/AlertContext"
import axios from "axios"
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import InputCustom from "../../components/InputCustom"
import AddBusinessSchema from './AddBusinessSchema'
import CountryCodeWithMobile from "../../components/CountryCodeWithMobile"
import CountryDropDown from "../../components/CountryDropDown"
import countryjson from '../../../../components/json/country_locale.json'
import TextAreaCustom from "../../components/TextAreaCustom"
import BusinessCategory from "../businessprofile/BusinessCategory"
import categories from '../../json/businessCategories.json'
import SocialMediaInput from "../../components/SocialMediaInput"
import GrayButton from "../../components/GrayButton"
import GreenButton from "../../components/GreenButton"
import PageTitle from "../../components/PageTitle"
import CountryStateCity from "../../components/CountryStateCity"
import CountryStateCityCom from "../../components/CountryStateCityCom"
import CountryStateCityComp from "../../components/CountryStateCityComp"
import H32 from "../../../../components/body/H32"
import { getValue } from "@mui/system"
import CountryStateCityCompSlave from "../../components/CountryStateCityCompSlave"
import CSC, { CSCContext, CSCProvider } from "../../components/CSC"
import CityStateCountry from "../../components/CityStateCountry"
import CityStateCountryTwo, { CountryProvider } from "../../components/CityStateCountryTwo"

export const AddBusinessContent = () => {
    const initialValues = {
        title: "",
        category_id: '',
        mobile: '',
        address_one: '',
        address_two: '',
        established: '',
        img: '',
        intro: '',
        business_category: '',
        business_phrases: '',
        email: '',
        country: '',
        state: '',
        city: ''
    }

    const { user, REACT_APP_API_URL } = useContext(GlobalContext)
    const [businessData, setBusinessData] = useState(null)
    const [loading, setLoading] = useState(false)
    let [phoneVal, setPhoneVal] = useState()
    const navigate = useNavigate()

    const LOCAL_FORM_STORE = 'ADD_BUSINESS_STORE'

    const getFormValues = () => {
        const storedValues = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))

        if (storedValues) {
            return storedValues
        } else {
            return initialValues
        }
    }


    const [formData, setFormData] = useState(getFormValues)
    const [working, setWorking] = useState(false)
    const { showAlert, timerRef, closeAlert, fadeOutAlert } = useContext(AlertContext)

    const [msg, setMsg] = useState()

    const [countryList, setCountryList] = useState(null)

    const getCountriesList = () => {
        const endpoint = REACT_APP_API_URL + "?operation=GET_COUNTRIES"
        //window.location.href = (endpoint)
        //return false;
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            //alert(result)
            //console.log(result)
            const data = (result.data.data)
            setCountryList(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    useEffect(() => {
        getCountriesList()
    }, [])

    const changeHandler = async (e) => {
        let value = e.target.value
        let name = await e.target.name
        await new Promise((resolve) => setTimeout(resolve, 100));
        setFormData((previousValue) => ({ ...previousValue, [name]: value }))
    }

    const setValueHandler = async (controlName, controlValue) => {

        setFormData((previousValue) => ({ ...previousValue, [controlName]: controlValue }))
    }

    const addBusiness = async (data) => {
        alert(JSON.stringify(data))

        //return false
        setWorking(true)
        await new Promise((resolve) => setTimeout(resolve, 1000));

        let xdata = JSON.stringify(data)
        let xobj = JSON.parse(xdata)
        xobj["user_id"] = user.user_id

        const endpoint = REACT_APP_API_URL + "?operation=ADD_BUSINESS"


        const res = await axios.post(endpoint, xobj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((rsp) => {

            showAlert("success", "Creation Successful.")

            setTimeout(() => {
                localStorage.setItem(LOCAL_FORM_STORE, null)
                showAlert("info", "Reloading...")

                setTimeout(() => {
                    window.location.href = ("/account/")
                }, 2000)

            }, 1000)


        }).catch((rsp) => {

            let err = (rsp.response.data.data[0].error)
            showAlert("info", "Error Encountered")
            console.log(err)
            fadeOutAlert(timerRef)
            setWorking(false)
        })
    }

    const onSubmit = async (data) => {
        showAlert("info", "Working...")
        console.log((data.cnty))
        console.log((data.cntycode))
        let status = await addBusiness(data)
        //alert(JSON.stringify(data))

    }



    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        setError,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        defaultValues: getFormValues(),
        resolver: zodResolver(AddBusinessSchema)
    })

    useEffect(() => {
        let tmpFormState = null;

        if (localStorage.getItem(LOCAL_FORM_STORE)) {
            tmpFormState = JSON.parse(localStorage.getItem(LOCAL_FORM_STORE))
        }
        setFormData((prev) => ({ ...prev, ...tmpFormState }))
    }, [])

    useEffect(() => {
        localStorage.setItem(LOCAL_FORM_STORE, JSON.stringify(formData))
    }, [formData, LOCAL_FORM_STORE])



    return (
        <div className=' mt-6'>

            <div className=" border-b-[1px] border-b-gray-500/50 pb-[16px] grid grid-cols-2">
                <PageTitle title={"Add Business"} />

                <div className="flex place-content-end place-items-center space-x-1 ">



                    <GrayButton
                        title="Go to home"
                        url="/account/home" />



                </div>
            </div>

            <div className=' mt-8'>
                <form id="upf" action={REACT_APP_API_URL + "?operation=EDIT_BUSINESS"} onSubmit={handleSubmit(onSubmit)}>
                    <div>

                        <div className=' grid lg:grid-cols-12 gap-4'>
                            <div className=' col-span-6'>


                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"title"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Business Name"}
                                    labelBottom={"Your business name may appear around Skyepages sites where you contribute or are mentioned. Your business name is a compulsory field"}
                                    placeholder={"Enter Business Name or Title"}
                                    error={errors?.title}
                                />

                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"email"}
                                    type={"text"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Email Address"}
                                    labelBottom={"Enter this business's email address. If it is empty, this account's email address's default email address is automatically selected. Which you can edit."}
                                    placeholder={"abc@domain.com"}
                                    error={errors?.email}
                                />


                                <InputCustom
                                    register={register}
                                    changeHandler={changeHandler}
                                    controlName={"established"}
                                    type={"number"}
                                    maximumLength={200}
                                    width={100}
                                    labelTop={"Year Established"}
                                    labelBottom={<div>Enter the year established for this business. It is not compulsory but it will boost your credibility.</div>}
                                    placeholder={"Year established: E.g. 1945"}
                                    error={errors?.established}
                                />


                            </div>
                            <div className=' col-span-6'>

                            </div>
                        </div>

                        {/* <div className='lg:w-[60%] md:w-[80%]'>
                            <CountryCodeWithMobile
                                register={register}
                                changeHandler={changeHandler}
                                getFormValues={getFormValues}
                                countryName={"country_code"}
                                phoneName={"mobile"}
                                labelTop={"Mobile"}
                                countryCodeError={errors?.country_code}
                                mobileError={errors?.mobile}
                            />
                        </div> */}

                        <div className="w-full md:w-[80%] lg:w-[50%]">
                            <CountryProvider>
                                <CityStateCountryTwo
                                    register={register}
                                    changeHandler={changeHandler}
                                    errors={errors}
                                    setValue={setValue}
                                    setValueHandler={setValueHandler}
                                />
                            </CountryProvider>
                        </div>

                        <H32 />

                        <div className="w-full md:w-[80%] lg:w-[50%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"zipcode"}
                                type={"number"}
                                maximumLength={50}
                                width={100}
                                labelTop={"Zip Code"}
                                labelBottom={<div>Please enter zip code. Zip code should be a valid one with maximum of 5 characters</div>}
                                placeholder={"Zip Code: E.g. 31945"}
                                error={errors?.zipcode}
                            />
                        </div>


                        <div className=' border-b-[1px] border-gray-700/20 mt-10 mb-[12px]'></div>
                        <div className=' mb-1 text-[17px] font-bold'>Business Address</div>
                        <div className=' text-[15px] tracking-tight font-light mb-6'>See sample entry below. Follow the entry below to add business address.</div>
                        <div className=' mb-6'>
                            <ul className=''>
                                <li className=' text-md'><b>Sample</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                            </ul>
                        </div>

                        <div className=" w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_one"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 1"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"612 Boulevard Avenue"}
                                error={errors?.address_one}
                            />
                        </div>

                        <div className="w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"address_two"}
                                type={"text"}
                                maximumLength={200}
                                width={100}
                                labelTop={"Address Line 2"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"New York, NY, 21345"}
                                error={errors?.address_two}
                            />
                        </div>

                        {/* <div className=" w-full md:w-[50%]">
                            <CountryDropDown
                                register={register}
                                changeHandler={changeHandler}
                                jsondata={countryjson}
                                controlName={"country"}
                                error={errors?.country}
                                labelTop={"Country"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters"}
                            />
                        </div> */}



                        {/* <div>
                            <CityStateCountry
                                register={register}
                                changeHandler={changeHandler}
                            />
                        </div>
                        <H32 /> */}

                        {/* <div className="w-full md:w-[60%]">
                            {
                                countryList !== null &&
                                <CountryStateCity
                                    register={register}
                                    changeHandler={changeHandler}
                                    countryList={countryList}
                                    countryName={"cntry"}
                                    stateName={"staten"}
                                    cityName={"city"}
                                    countryLabelTop={"Country"}
                                    stateLabelTop={"State"}
                                    cityLabelTop={"City"}
                                    countryLabelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters"}
                                    stateLabelBottom={"Enter the state the business is situated in. "}
                                    cityLabelBottom={"Enter the city the business is situated in"}
                                />
                            }
                        </div> */}

                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[12px]'></div>
                        <div className=' text-[18px]'>Sample Completed Profile</div>
                        <div className=' text-[16px] tracking-tight font-normal mb-6'>If your entry is complete, your address should look like the following below. To get a complete address like the one below, fill all your details. They will automatically appear.</div>
                        <div className=' mb-6'>
                            <ul className=' text-[17px]'>

                                <li className=''><b>Appler, Inc.</b></li>
                                <li className=' text-sm'>612 Boulevard Avenue</li>
                                <li className=' text-sm'>New York, NY, 21345</li>
                                <li className=' text-sm'>United States of America</li>
                                <li className=' text-sm'>+1 (704) 345 8736</li>
                            </ul>
                        </div>

                        {
                            (
                                formData.title
                            ) && <div className=' mb-6 px-6 bg-gray-100 py-5 rounded-[12px] border-[1px] border-gray-500/80 w-[80%]'>
                                <ul className=' text-[17px] '>

                                    <li className=''><b>{formData?.title}</b></li>
                                    <li className=' text-sm'>{formData?.address_one}</li>
                                    <li className=' text-sm'>{formData?.address_two}</li>
                                    <li className=' text-sm'>{formData?.city && formData?.city + ", "} {formData?.state_code && formData?.state_code + ","} {formData?.zipcode && formData?.zipcode}</li>
                                    <li className=' text-sm'>{formData?.country_text} {formData?.country}</li>
                                    <li className=' text-sm'>{formData?.call_code && "+" + formData?.call_code} {formData?.call_mobile}</li>

                                </ul>
                            </div>
                        }




                        <div className=' border-b-[1px] border-gray-700/20 mt-[32px] mb-[32px]'></div>


                        <div className="w-full md:w-[80%]">
                            <TextAreaCustom
                                register={register}
                                changeHandler={changeHandler}
                                getValues={getValues}
                                controlName={"intro"}
                                maximumWords={140}
                                height={300}
                                labelTop={"Business Introductory Information"}
                                labelBottom={"Enter the maximum number of words displayed above the text area. Additional texts will be automatically removed."}
                                error={errors?.intro}
                            />
                        </div>


                        <div className=" w-full md:w-[80%]">

                            <BusinessCategory
                                register={register}
                                changeHandler={changeHandler}
                                jsondata={categories}
                                controlName={"business_category"}
                                labelTop={"Business Category"}
                                error={errors?.business_category}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />


                        </div>


                        <div className=" w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"business_phrases"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Business Phrases"}
                                labelBottom={"Enter your business phrases. E.g. Advocate, Plumber , Builder, Software Engineer."}
                                placeholder={"Enter business phrases. E.g. Advocate, Architect, Software Engineer, Plumber"}
                                error={errors?.business_phrases}
                            />
                        </div>

                        <div className=" border-t-[1px] my-[32px] pt-[10px] text-[25px] text-gray-600">
                            Optional Area
                        </div>


                        <div className=" w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"products"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Products"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"Products. E.g. Shoes, Business Manual, Watch, Digital Wallet, Crypto"}
                                error={errors?.products}
                            />
                        </div>

                        <div className=" w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"services"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Services"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"Services. E.g. Car wash, Machining and Tooling, Audit, IT Services, Personal Assistant."}
                                error={errors?.products}
                            />
                        </div>


                        <div className=" w-full md:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"xsoc"}
                                controlLabel={"http://x.com/"}
                                error={errors?.xsoc}
                                labelTop={"Twitter"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>


                        <div className=" w-full md:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"fbsoc"}
                                controlLabel={"http://facebook.com/"}
                                error={errors?.linksoc}
                                labelTop={"Facebook"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>

                        <div className=" w-full md:w-[80%]">
                            <SocialMediaInput
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"linksoc"}
                                controlLabel={"http://linkedin.com/company/"}
                                error={errors?.linksoc}
                                labelTop={"LinkedIn"}
                                labelBottom={"Enter country of residence or country your business is registered in. Where a business is situated in more than one country, enter the country that represents the head quarters."}
                            />
                        </div>


                        <div className=" w-full md:w-[80%]">
                            <InputCustom
                                register={register}
                                changeHandler={changeHandler}
                                controlName={"website"}
                                type={"text"}
                                maximumLength={300}
                                width={100}
                                labelTop={"Website"}
                                labelBottom={"Enter anofficial business address. This can be any registered business address or an address that clients or customers can access."}
                                placeholder={"http://business.com or http://www.business.com"}
                                error={errors?.website}
                            />
                        </div>




                        <div className='flex flex-col mb-6 w-[80%]'>


                            <label className='label-description'>
                                By Submitting, I accept the terms and privacy policy.  All of the fields on this page can be deleted at any time, and by filling them out, you're giving us consent to share this data wherever your business profile appears. Please see our <span className="b"><u><a href="/privacy">Privacy Statement</a></u></span> to learn more about how we use this information
                            </label>

                            {/* <input type="hidden" name="operation" value="EDIT_BUSINESS" /> */}
                            {/* {errors && <div>
                                {

                                    console.log(errors)
                                }
                            </div>} */}
                            <div className=' mt-5'>
                                <GreenButton
                                    title={"Add Business"}
                                    working={working}
                                />


                            </div>
                        </div>


                    </div>
                </form>
            </div >
        </div >
    )
}