import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function Logo({
    isScrolling,
    colorInfo,
    fullLogoOnMobile,
    colorOnScroll,
    logoBgColor,
    logoBgColorOnScroll,
    logoTextColor,
    logoTextColorOnScroll,
    circularLogo
}) {
    const [color, setColor] = useState("white")
    const [colorOnScr, setColorOnScr] = useState()
    const [fullLogo, setFullLogo] = useState(false)

    useEffect(() => {
        if (fullLogoOnMobile == true) {
            setFullLogo(fullLogoOnMobile)
        }
    }, [fullLogoOnMobile])


    useEffect(() => {
        //alert(colorInfo)
        if (colorInfo) {
            setColor(colorInfo)

        }
        if (colorOnScroll) {
            setColorOnScr(colorOnScroll)

        }
    }, [colorInfo, colorOnScroll])
    return (
        <div>
            <div className='flex'>
                <NavLink onClick={() => {
                    window.location.href = "/"
                }}>
                    <span className={` text-[32px]  font-sans font-black ${isScrolling ? colorOnScr : color} 
                    tracking-tighter `}>
                        <div className={` flex place-content-center place-items-center 
                              h-[35px]`}>
                            <div className={`relative w-[45px] h-[45px] flex md:w-[40px] md:h-[40px] md:rounded-full 
                            place-content-center place-items-center font-sans
                            ${isScrolling ? logoBgColorOnScroll : logoBgColor}
                            ${isScrolling ? colorOnScroll : colorInfo}
                            ${circularLogo ? 'rounded-full' : 'rounded-none'}
                            `}>
                                <div className={``}>
                                    st.
                                </div>
                            </div>
                            <span className={`
                            ${isScrolling ? logoTextColor : logoTextColorOnScroll} 
                            text-[25px] 
                            h-[35px] ${fullLogo == true ? '' : 'hidden'} 
                            place-content-center place-items-center leading-3
                            
                            md:block md:place-content-center md:place-items-center 
                            `}>storcs.</span>
                        </div>
                    </span>
                </NavLink>
            </div>
        </div >
    )
}

export default Logo
