import { GlobalContext, GlobalSetterContext, useGlobal } from '../../context/GlobalContext';
import React, { act, useContext } from 'react'
import { Link } from 'react-router-dom';
import { BsFacebook, BsInstagram, BsPinterest, BsTwitter, BsTwitterX } from 'react-icons/bs'
import { ArrowRightIcon } from '@heroicons/react/outline';

import Logo from './Logo';
import { Routes, Route } from "react-router-dom";
import { BiLogoFacebook, BiLogoInstagram, BiLogoInstagramAlt, BiLogoPinterest, BiLogoPinterestAlt, BiLogoTwitter } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { RxPlay } from 'react-icons/rx';
import SettingsLeftNav from '../../pages/account/components/SettingsLeftNav';
import { AiOutlineAim, AiOutlineHome, AiOutlineShoppingCart, AiTwotonePlusCircle } from 'react-icons/ai';
import { UtilContext } from '../../context/UtilContext';

const genericLinks = [
    {
        id: 1,
        link: "/",
        title: "Home",
        icon: <AiOutlineShoppingCart size={20} />
    },
    {
        id: 2,
        link: "/search",
        title: "Search",
        icon: <AiTwotonePlusCircle size={20} />
    },
    {
        id: 3,
        link: "/privacy",
        title: "Privacy",
        icon: <AiOutlineAim size={20} />
    },
    {
        id: 4,
        link: "/advertise",
        title: "Advertise",
        icon: <AiOutlineAim size={20} />
    },
    {
        id: 5,
        link: "/contact",
        title: "Contact",
        icon: <AiOutlineAim size={20} />
    },
    {
        id: 6,
        link: "/login",
        title: "Login",
        icon: <AiOutlineAim size={20} />
    },
]





function MobileMenu() {

    let { horizontalNav, toggler, mobileMenu, user } = useContext(GlobalContext)
    let { setHorizontalNav, setToggler, setMobileMenu } = useContext(GlobalSetterContext)
    const { getUrlId,
        getSubstr } = useContext(UtilContext)

    const location = useLocation();
    const { hash, pathname, search } = location

    //console.log(mobileMenu)

    const getNavLinks = (data) => {
        return data.map((item) => {
            let activatedLink = false;

            if (pathname === item.link) {
                activatedLink = true;

            } else {
                activatedLink = false;

            }

            return (
                <div key={item.id} className={`${activatedLink ? 'bg-gray-500/20' : ''} 
                    @apply px-3 rounded-[6px] hover:cursor-pointer hover:bg-gray-500/15 py-[8px] font-semibold flex space-x-3 place-content-start place-items-center

                `}>
                    <div>
                        {item.icon}
                    </div>
                    <div className={`inline-block w-full`}>
                        <a className='' href={item.link}>
                            <div>
                                {item.title}
                            </div>
                        </a>
                    </div>
                </div>
            )
        })
    }

    const getNavHeader = (hdrText, icon) => {
        return (
            <div className='py-[8px] font-bold 
            text-[17px] flex space-x-3 
            place-content-start 
            place-items-center
 '>
                <div>
                    {icon}
                </div>
                <div className=' relative top-[2px]'>
                    {hdrText}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={` mt-[0px] md:mt-[0px] overflow-y-auto  duration-300 
            flex flex-col w-[350px] sm:w-[390px] h-full fixed text-white top-[0px]
            ${toggler ? `left-[-2px]` : `left-[-400px]`} blur:hidden 
             bg-white
             shadow-xl`}>

                <div className='  h-auto pt-[0px]'>

                    <div className='pl-[50px]  pb-[18px] pt-[18px] border-b-[1px] border-b-gray-500/50 shadow-lg mb-10'>

                        <Logo
                            isScrolling={false}
                            colorInfo={"text-white"}
                            colorOnScroll={"text-white"}
                            logoTextColor={"text-[#4b0d96]"}
                            logoTextColorOnScroll={"text-[#4b0d96]"}
                            logoBgColor={"bg-[#4b0d96]"}
                            logoBgColorOnScroll={"bg-white"}
                            fullLogoOnMobile={true}
                        />

                    </div>

                    <div className=' pl-[50px] text-black pr-[15px]'>
                        {<SettingsLeftNav />}
                    </div>

                    <div className=' pl-[50px] text-black pr-[15px]'>



                    </div>


                    <div className='relative flex gap-5 items-center mt-28'>
                        <div className='bg-cyan-600 w-[5px] h-[1.5px] '></div>
                        <Link href="https://www.facebook.com/comvarse" target='_blank'><span><BiLogoFacebook size={27} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.twitter.com/comvarse" target='_blank'><span><BiLogoTwitter size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.instagram.com/comvarse" target='_blank'><span><BiLogoInstagram size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.pinterest.com/comvarse" target='_blank'><span><BiLogoPinterestAlt size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu
