import React from 'react'
import Logo from '../header/Logo'
import { BsFacebook, BsTwitterX, BsInstagram, BsPinterest } from 'react-icons/bs'
import { BiLogoFacebook, BiLogoTwitter, BiLogoInstagram, BiLogoPinterest } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import LogoFooter from '../header/LogoFooter'

function Footer() {
    const iconsList = [
        { icon: <BiLogoFacebook />, url: "http://facebook.com/skyepages" },
        { icon: <BiLogoTwitter />, url: "http://twitter.com/skyepages" },
        { icon: <BiLogoInstagram />, url: "https://instagram.com/skyepages" },
        { icon: <BiLogoPinterest />, url: "http://pinterest.com/skyepages" }
    ]

    const ftrLnks = [
        {
            title: "Contact us",
            lnk: "/contact"
        },
        {
            title: "Privacy",
            lnk: "/privacy"
        },
    ]


    return (
        <div>
            <div className='bg-[#4b0d96] px-5 '>
                <div className=' py-20 max-w-[1200px] mx-auto w-full space-y-1'>
                    <div className=' grid grid-cols-12'>
                        <div className=' col-span-7 space-y-10'>
                            <div>


                                <LogoFooter
                                    isScrolling={false}
                                    colorInfo={"text-[#4b0d96]"}
                                    colorOnScroll={"text-white"}
                                    logoTextColor={"text-[#4b0d96]"}
                                    logoTextColorOnScroll={"text-white"}
                                    logoBgColor={"bg-white"}
                                    logoBgColorOnScroll={"bg-white"}
                                    fullLogoOnMobile={true}
                                    circularLogo={true}
                                />



                            </div>
                        </div>
                        <div className=' col-span-5 space-y-10'>

                        </div>
                    </div>

                    <div className=' grid grid-cols-1 lg:grid-cols-12 gap-8'>
                        <div className=' lg:col-span-7'>
                            <div className=' text-white text-[17px] tracking-tighter'>
                                Stay ahead in a rapidly changing world by working with us. We provide strategic recommendations for new opportunities.
                            </div>
                        </div>
                        <div className=' lg:col-span-5  flex text-[25px] space-x-8  text-white lg:place-content-end'>
                            {
                                iconsList.map((item) => {
                                    return (
                                        <a key={item.url} href={item.url}>
                                            {item.icon}
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className=' h-1'></div>

                    <div className=' grid grid-cols-1 gap-2 text-white/80 lg:flex lg:space-x-5'>
                        {
                            ftrLnks.map((item) => {
                                return (
                                    <div key={item.lnk} className=' text-white'>
                                        <a href={item.lnk} className='hover:text-white/50'>
                                            {item.title}
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className=' w-full h-1 border-t-[1px] border-white/20'></div>
                    <div className=' text-md text-white/50'>
                        © 2024 | A Storcs Company.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
