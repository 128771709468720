import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'




const phoneRegex = new RegExp(/^(?:\+1)?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/);
const zipcoderegex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/)

const getMaxWords = (wordsInput, maxWords) => {


    let reconstruct = ""
    let wordsLength = 0
    let testWordLength = wordsInput.length

    if (testWordLength > 0) {

        let trimmedText = wordsInput.trim()

        let words = trimmedText.split(" ")
        wordsLength = words.length


        for (let i = 0; i < maxWords; i++) {
            let word = words[i]

            if (i < wordsLength) {
                if (i == (maxWords - 1)) {
                    reconstruct += word
                } else {
                    reconstruct += word + " "
                }
            }
        }
    }

    return wordsLength
}

function isValidPostalCode(postalCode, countryCode) {
    let postalCodeRegex
    switch (countryCode) {
        case "US":
            postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;

            break;
        case "CA":
            postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
            break;
        default:
            postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(postalCode);
}

const AddBusinessSchema = z.object({
    title: z.string()
        .min(1, { message: "Enter a business name" })
        .min(3, { message: "Busines Name must not be less than 3 characters" })
        .max(100, { message: "Business name must not be more than 100 characters." }),
    /* country_code: z.string()
        .min(1, { message: "Please enter a country code." }),
    mobile: z.string()
        .min(1, { message: "Please enter a valid mobile or phone number" })
        .min(5, { message: "Mobile number must not be less than 5 characters" })
        .max(18, { message: "Mobile number must not be more than 15 characters" }), */
    address_one: z.string()
        .min(3, { message: "Address must not be less than 3 characters" })
        .max(100, { message: "Address must not be more than 100 characters" }),
    address_two: z.string()
        .max(100, { message: "Address must not be more than 100 characters" }),
    country_code: z.string()
        .min(1, { message: "Please select country country." }),
    state_code: z.any(),
    state_text: z.any(),
    country_text: z.any(),
    city: z.any(),
    established: z.string(),
    call_code: z.any(),
    call_mobile: z.any(),
    zipcode: z.any(),
    intro: z.string()
        .refine(
            (val) => getMaxWords(val, 100) > 99,
            ({ message: `Intro should be more than 100 words` })
        )
        .refine(
            (val) => getMaxWords(val, 140) <= 140,
            ({ message: `Intro should not be more than 140 words` })
        ),

    business_phrases: z.string()
        .min(3, { message: "Business Phrases must not be less than 3 characters" })
        .max(100, { message: "Business Phrases must not be more than 100 characters" }),
    business_category: z.string()
        .min(1, { message: "Please select a business category." }),
    products: z.any(),
    services: z.any(),
    xsoc: z.any(),
    fbsoc: z.any(),
    linksoc: z.any(),
    website: z.any(),
    email: z.any(),


}).refine(data => isValidPostalCode(data.zipcode, data.country), {
    message: "Please enter a valid zip code",
    path: ['zipcode'] // Pointing out which field is invalid
});


export default AddBusinessSchema
