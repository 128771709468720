import React, { useContext, useEffect, useState } from 'react'
import { GrLocation } from 'react-icons/gr'
import countryjson from '../../../json/country_locale.json'
import { GlobalContext } from '../../../../context/GlobalContext'
import axios from 'axios'

const Where = ({ register, setValue, changeHandler }) => {
    const [results, setResults] = useState([])
    const { REACT_APP_API_URL } = useContext(GlobalContext)
    const [cities, setCities] = useState(null)
    const [states, setStates] = useState(null)
    const [countries, setCountries] = useState(null)

    const getCountry = (value) => {
        const results = countryjson.filter((country) => {
            return (
                country.countryName.toLowerCase().includes(value)
            )
        })
        setResults(results)
    }
    const fetchData = (value) => {
        fetch("https://jsonplaceholder.typicode.com/users")
            .then((response) => response.json())
            .then((json) => {
                const results = json.filter((user) => {
                    return (
                        value &&
                        user &&
                        user.name &&
                        user.name.toLowerCase().includes(value)
                    )
                })

                setResults(results)
            })
    }

    const fetchWhereData = (value) => {
        let endpoint = REACT_APP_API_URL + "?operation=GET_WHERE"
        endpoint += "&where=" + encodeURI(value)
        //alert(endpoint)
        //window.location.href = endpoint
        //return false

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            //alert(result)
            //console.log(result)
            const data = (result.data.data)

            //console.log(data)
            setResults(data)
        }).catch((result) => {
            console.log(result)
        })
    }


    const filterData = (value, cities, states, countries) => {

        const results = cities.filter((city) => {
            return (
                city.name.toLowerCase().includes(value.toLowerCase())
            )
        })
        setResults(results)
    }

    const loadCities = () => {
        let endpoint = REACT_APP_API_URL + "?operation=GET_ALL_CITIES"

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            const data = (result.data.data)
            //alert(data)
            setCities(JSON.parse(JSON.stringify(data)))
        }).catch((result) => {
            console.log(result)
        })
    }

    const loadStates = () => {
        let endpoint = REACT_APP_API_URL + "?operation=GET_ALL_STATES"

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            const data = (result.data.data)
            setStates(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    const loadCountries = () => {
        let endpoint = REACT_APP_API_URL + "?operation=GET_ALL_COUNTRIES"

        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((result) => {
            const data = (result.data.data)
            setCountries(data)
        }).catch((result) => {
            console.log(result)
        })
    }

    useEffect(() => {
        //loadCities()
        //loadStates()
        //loadCountries()
    }, [])


    useEffect(() => {
        if (cities) {
            //alert(JSON.stringify(cities[0]))
        }
    }, [cities])


    const loadData = (value) => {

        //getCountry(value)
        if (value !== "" && value.length > 2) {
            fetchWhereData(value)
        }
        //filterData(value) 
    }

    useEffect(() => {
        if (results) {
            //console.log(results)
            //console.log(results.length)
        }
    }, [results])
    return (
        <div className='border-[1px] border-gray-500/30 h-[45px] w-[44%]
                px-[4px] py-[0px] flex place-items-center gap-2 bg-white
            '>
            <div className='ml-1 place-content-center'>
                <GrLocation size={17} />
            </div>
            <div
                className='place-content-center w-full bg-blue-500 '
                onBlur={async () => {
                    const resultslist = document.getElementById("results-list")
                    if (resultslist) {
                        await new Promise((resolve) => setTimeout(resolve, 200));
                        resultslist.style.display = 'none'
                    }

                }}
            >
                <input
                    {...register("where", {
                        onChange: changeHandler
                    })}
                    className=' text-[16px] outline-none 
                             w-full tracking-tight justify-center'
                    placeholder='Where?'
                    type="text"
                    id='xbar'
                    onKeyUp={(e) => {
                        loadData(e.target.value)
                    }}
                    onFocus={(e) => {
                        const resultslist = document.getElementById("results-list")
                        if (resultslist) {
                            resultslist.style.display = 'block'
                        }
                    }}


                />


            </div>
            {results && results.length > 1 &&
                <SearchResultsList setValue={setValue} results={results} />
            }
        </div>
    )
}


export const SearchResultsList = ({ results, setValue }) => {

    return (
        <div>
            {

                <div id='results-list' className='w-[100%] md:w-[50%] lg:w-[50%] 
        right-[0px] sm:right-[12px] md:right-[5px] lg:right-[27px]
        absolute top-[40px]  z-30 bg-white flex flex-col border-[1px]
         border-gray-500 shadow-md rounded-b-[0px] mt-[5px] max-h-[300px]
         overflow-y-auto'>
                    <div className=' relative z-10'>
                        {
                            results.map((result, id) => {
                                let val = ""
                                val += (result?.name !== undefined) ? result?.name : ''
                                val += (result.state_name) ? ", " + result.state_name : ''
                                val += (result.country_name) ? ", " + result.country_name : ''
                                return (
                                    <SearchResult setValue={setValue} result={result.place} key={id} />
                                )
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}


export const SearchResult = ({ result, setValue }) => {

    return (
        <div
            className=' px-[20px] cursor-pointer py-[10px] hover:bg-blue-300
             text-[14px] flex flex-col'
            onMouseDown={async (e) => {

                document.getElementById("xbar").value = result
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setValue("where", result)
                document.getElementById("results-list").style.display = 'none'
            }}
            id='search-result'
        >
            {result}
        </div>
    )
}

export default Where
