import React, { useContext, useEffect, useState } from 'react'
import HeaderLightSearch from '../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../components/header/HeaderSpace'
import { useSearchParams } from 'react-router-dom'
import { GlobalContext, GlobalSetterContext } from '../../context/GlobalContext'
import axios from 'axios'

const ChangeEmailLanding = () => {
    let [searchParams] = useSearchParams()
    const userId = searchParams.get("id")
    const requestId = searchParams.get("request_id")
    let { REACT_APP_API_URL, user, gcolors } = useContext(GlobalContext)
    let { logoutUserWithoutRedirect } = useContext(GlobalSetterContext)
    const [changeEmaildata, setChangeEmailData] = useState(null)
    const [changeEmailError, setChangeEmailError] = useState(null)

    const getStatus = async () => {
        let endpoint = REACT_APP_API_URL + "?operation=WECR"
        endpoint += "&user_id=" + userId
        endpoint += "&request_id=" + requestId

        //window.location.href = (endpoint)

        let dat = await axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((resp) => {
            //alert(resp.data.data[0])
            setChangeEmailData(resp.data.data[0])
        }).catch(function (e) {
            let error = e.response.data?.data[0]
            let errorInfo = error.error

            errorInfo && setChangeEmailError(errorInfo)
        })


        /* if (res.status === 200) {
            setData(res.data.data[0])
            return true
        } else {
            setChangeEmailError("Sorry, we could not complete the request this time.")
            return false
        } */
    }

    useEffect(() => {
        getStatus()
        if (user) { logoutUserWithoutRedirect() }
    })

    return (
        <div>
            <HeaderLightSearch
                bgColor={"bg-transparent"}
                bgColorOnScroll={"bg-white"}
                logoColor={"text-[#fff]"}
                logoTextColor={"text-[#4b0d96]"}
                logoTextColorOnScroll={"text-[#4b0d96]"}
                logoColorOnScroll={"text-[#fff]"}
                logoBgColor={"bg-[#4b0d96]"}
                logoBgColorOnScroll={"bg-[#4b0d96]"}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <div className='max-w-[600px] mx-auto w-full flex flex-col place-content-center
            h-screen px-5'>
                <div className='
                py-2 px-6 border-[1px] border-gray-300
                rounded-[12px] shadow-md
                '>
                    <div className=' my-5'>
                        <div className=' text-[22px] font-black tracking-tight  bg-blue-100 px-4 py-1'>
                            {changeEmaildata ? <div>Welcome, {changeEmaildata?.first_name}</div> : 'Link Expired'}
                        </div>
                    </div>

                    <div className='  pt-0 pb-12'>
                        <div className=' grid grid-cols-1 gap-4'>
                            {
                                changeEmaildata !== null ? <div className='  space-y-4'>
                                    <div>
                                        You have successfully changed your email.
                                    </div>
                                    <div className=' border-[1px] border-gray-600/30 rounded-md p-4 text-[16px] font-semibold'>
                                        Your new email is <b>{changeEmaildata.email}</b>
                                    </div>

                                    <div className=' space-x-2'>
                                        <span>Click here to sign into your account.</span>
                                        <a href="/login" className=' outline-none'>
                                            <button
                                                className=' bg-green-700 hover:bg-green-900 rounded-[4px] text-white px-3 border-[1px] border-blue-900/30 pt-[0px] pb-[2px]'
                                            >
                                                Login</button>
                                        </a>
                                    </div>
                                </div> :
                                    <div>
                                        <div className=' border-[1px] border-gray-600/30 rounded-md p-4 text-[14px] font-semibold'>
                                            <b>{changeEmailError && changeEmailError}</b>
                                        </div>
                                    </div>

                            }



                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeEmailLanding
