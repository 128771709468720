import { DesktopComputerIcon, PhoneIcon, PhoneIncomingIcon } from '@heroicons/react/outline';
import { Box } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import countryCode from '../../../../components/json/country'

const country = [
    { id: "AG", name: "Antigua & Babuda" }
]

function escapeHtml(text) {
    var map = { amp: '&', lt: '<', gt: '>', quot: '"', '#039': "'" };
    var output = text.replace(/&([^;]+);/g, (m, c) => map[c]);
    return output
}

const getCountry = (id, country) => {
    let countryName = ""
    country.map((item) => {
        if (item.id === id) {
            console.log(item.countryName)
            countryName = escapeHtml(item.countryName)
        }
    })
    return countryName
}

let counter = 0;
const columns = [

    {
        field: 'title',
        headerName: 'Business Name',
        width: 340,
        editable: true,
        flex: 1
    },
    {
        field: 'mobile',
        headerName: 'Mobile',
        width: 150,
        editable: true,
        flex: 1,
        renderCell: (params) => {
            return (
                <div className=''>
                    +{params.row.call_code} {params.row.call_mobile}
                </div>
            )
        }
    },
    {
        field: 'country',
        renderHeader: (params) => (
            <div>Country</div>
        ),

        width: 250,
        editable: true,
        flex: 1,
        renderCell: (params) => {
            return (
                <div className=''>
                    {params.row.country_text}
                </div>
            )
        }
    },
    {
        field: 'action',
        headerClassName: ' w-full flex place-items-end',
        headerAlign: 'right',
        width: 150,
        editable: true,
        flex: 1,
        renderCell: (params) => {
            let id = params.row.business_id

            return (
                <div className=' relative space-x-2 text-end'>
                    <Link to={"/account/business-profile/" + id}
                        className=' font-bold'
                    >Edit</Link>


                </div>
            )
        },
        renderHeader: (params) => (
            <div className=''>
                <strong>
                    {'Action '}
                    <span role="img" aria-label="enjoy">
                        🎂
                    </span>
                </strong>
            </div>
        ),
    },

];

const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];


const AllBusinessesGrid = ({ data }) => {
    //console.log(data)
    const autosizeOptions = {
        includeOutliers: true,
    };


    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5]}
                        /* checkboxSelection */
                        disableRowSelectionOnClick
                        autoHeight

                    />
                </div>
            </Box>
        </div>
    )
}

export default AllBusinessesGrid
