import React, { useContext, useEffect, useRef } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import { GlobalContext } from '../../context/GlobalContext';



function Prefooter() {


    const callUrl = (url) => {
        window.location = "mailto:info@storcs.com";
    }

    const { gcolors } = useContext(GlobalContext)


    return (
        <div>
            <div className={`grid lg:grid-cols-2 border-t-[1px] border-gray-500/30 py-24 divide-y-[1px] text-black divide-x-0 sm:divide-y-[1px] divide-black/50  lg:divide-x-[1px] lg:divide-y-0 `}>
                <div className=' relative pb-40 pt-24 lg:pt-16 lg:pb-16'>
                    <div className=' max-w-[60%] mx-auto w-full space-y-6'>
                        <div className='  text-[35px] tracking-tight leading-tight text-center font-semibold'>
                            What goal do you wish to achieve?
                        </div>
                        <div className=' text-center'>
                            {/* <button type='button' onClick={() => callUrl('http://google.com')} className=' border-[1px] border-white/80 
                            px-6 py-4 text-sm text-white uppercase hover:bg-black/40 hover:border-black/40'>
                                info@syber.com
                            </button> */}
                            <div className='  text-xl'>Email us: advert@storcs.com</div>
                        </div>
                    </div>
                </div>
                <div className=' relative pb-24 pt-40 lg:pt-16 lg:pb-16 border-l-[1px] border-white/20'>
                    <div className=' max-w-[60%] mx-auto w-full space-y-6'>
                        <div className=' text-[35px] leading-tight text-center font-semibold'>
                            Are you uncertain where to start?
                        </div>
                        <div className=' text-center'>
                            {/* <button className=' border-[1px] border-white/80 
                            px-6 py-4 text-sm text-white uppercase hover:bg-black/40 hover:border-black/40'>
                                support@syber.com
                            </button> */}
                            <div className=' text-xl'>Email us: care@storcs.com</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Prefooter
