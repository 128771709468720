import React, { useContext, useEffect, useState } from 'react'
import HeaderLightSearch from '../../../../components/header/headerLight/HeaderLightSearch'
import HeaderSpace from '../../../../components/header/HeaderSpace'
import SettingsLeftNav from '../../components/SettingsLeftNav'
import SubHeaderNav from '../../components/SubHeaderNav'

import { GlobalContext } from '../../../../context/GlobalContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BusinessProfileProvider } from '../../../../context/BusinessProfileContext'
import { UtilContext } from '../../../../context/UtilContext'
import { PhotoGalleryUpload } from './PhotoGalleryUpload'
import Prefooter from '../../../../components/footer/Prefooter'
import Footer from '../../../../components/footer/Footer'
import DisplayBusinessGallery from './DisplayBusinessGallery'
const PhotoGallery = () => {
    const { user, REACT_APP_API_URL, gcolors } = useContext(GlobalContext)
    const [loading, setLoading] = useState(true)
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate()
    const [businessData, setBusinessData] = useState(null)
    const [businessGalleryData, setBusinessGalleryData] = useState([])
    const { getUrlId } = useContext(UtilContext)
    const [id, setId] = useState("")

    useEffect(() => {
        const endpoint = REACT_APP_API_URL + "?operation=READ_USER&id=" + user.user_id
        //window.location.href = (endpoint)
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                //console.log(res.data)
                //alert(res.data.data[0])
                const data = JSON.stringify(res.data.data[0])

                setUserData(data)
                setLoading(false)
                //alert(userData)
            })
    }, [setUserData])



    useEffect(() => {
        setId(getUrlId())
    }, [])

    function getBusinessData(id) {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data[0])

                setBusinessData(data)
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        if (id) {
            getBusinessData(id)
        }
    }, [id])

    function getBusinessGalleryData(id) {
        const endpoint = REACT_APP_API_URL + "?operation=READ_BUSINESS_GALLERY_DATA&id=" + id
        //window.location.href = endpoint
        //return false
        axios.get(endpoint, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                const data = JSON.stringify(res.data.data)
                console.log(data)
                //alert(data)
                setBusinessGalleryData(data)
                setLoading(false)
                //alert(businessData)
            })
            .catch((res) => {
                console.log(res)
            })
    }

    useEffect(() => {
        if (id) {
            getBusinessGalleryData(id)
        }
    }, [id])


    return (
        <div>
            <HeaderLightSearch
                bgColor={gcolors.headerMain}
                bgColorOnScroll={gcolors.headerMainScroll}
                color={"text-black"}
                colorBorder={"border-b-[1px] border-b-black"}
                colorOnScroll={"text-black"}
                borderOnScroll={"border-b-[1px] border-black"}
                borderColorOnHover={"border-b-[1px] border-b-black"}
            />

            <HeaderSpace />

            <div className='mx-5'>
                <div className=' max-w-[1200px] mx-auto w-full grid lg:grid-cols-12 gap-8'>
                    <div className='hidden lg:block lg:col-span-3'>
                        <SettingsLeftNav />
                    </div>
                    <div className=' lg:col-span-9'>


                        {
                            (userData && businessData && businessGalleryData) ?
                                <PhotoGalleryUpload
                                    user={user}
                                    userData={userData}
                                    businessData={businessData}
                                    businessGalleryData={businessGalleryData}
                                />
                                : 'Waiting for profile data...'
                        }
                    </div>


                </div>
            </div>

            <HeaderSpace />

            <Prefooter />

            <Footer />
        </div>
    )
}

export default PhotoGallery
